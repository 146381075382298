import React from "react";
import { Helmet } from "react-helmet";
import { MainServerUrl } from "../BasePath";
import { useEffect, useState } from "react";
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { changeBaseUrl } from "../utils/changeBaseUrl";
// import { Defer } from "react-progressive-loader";
// import Loading from './Loading';



export const Slideshow = (props) => {

  const [slideshow, setSlideshow] = useState([])
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 5000);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      signal: controller.signal,
    };
    setSlideshow([]);

    fetch(`${MainServerUrl}/api/slideshow/getall`, requestOptions)
      .then((response) => {
        console.log(response)
        if (!response.ok) {
          throw new Error("response is not ok");
        }
        if (response.status !== 200) {
          throw new Error("Status code is not 200");
        }
        return response.json();
      })
      .then((data) => {
        setSlideshow(data)
        if (props.images) {
          // console.log(props.images)
          setSlideshow(props.images);
        }
      })
      .catch((err) => {
        console.log("Error", err);

      })
      .finally(() => clearTimeout(id));

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);





  
  return (
    <React.Fragment>
      <Helmet>
        <script src={process.env.PUBLIC_URL + "/js/core.min.js"}></script>
        <script src={process.env.PUBLIC_URL + "/js/script.js"}></script>
      </Helmet>
      <div style={{ width: "100%", maxHeight: "100%"}} className="slide-container">
        <Zoom scale={0.4} transitionDuration={1000} duration={8000}>

          {
            slideshow.map((item, index) => {
              var url = changeBaseUrl(item.url);
              return (<img key={index} style={{ width: "100%", maxHeight: "100%" }} src={url} alt={index} />)
            })
          }
        </Zoom>
      </div>
    </React.Fragment>
  )
};

export default Slideshow;
