
// source -> components
import React from 'react'

const Patents = () => {
    return (
        <div>


            <div class="flex">
                {/* <PhdSeminars autoPlay={true} carouselTitle={"Ph. D seminars"} /> */}

                <h4 class="fw-bold subchildEventsPublication">Patents/ Technology Transfer/ Startup</h4>
                <div class="text-subline"></div><br />




                <div className="bar">
                <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">Prof. Bose and Sri Nikhil Bharat (Department of Mechanical Engineering) have been granted an Indian Patent for their invention, "A Method of Manufacturing A Nanocomposite Material"</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                </div>
                <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">"Focused Sunlight Mediated Conversion In A Newly Designed Homemade Glove Box Integrated With Convex Lens For Energy Storage" has been granted to Prof. Mukul Pradhan and Rudra, Rushika</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                </div>
                <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">"MULTI FUNCTIONAL CROP PROTECTION DEVICE" by Jatoth Shivaram, Davarath Krishna, Hari Kumar Voruganti, Karthik Balasubramanian  </h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                </div>
                <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">An  Indian patent has been granted to P. Abdul Azeem, P. Srinath, k. Venugopal Reddy, S. Rajkumar, M. Bramanandam, M. Krishnamraju and Sushil Patel entitled “ CALCIUM SILICATE BASED NANO CRYSTALLINE CERAMICS: A COST-EFFECTIVE METHOD FOR THE PREPARATION USING NATURAL RESOURCES” on September 2021, Grant No. 376586, Application No. 2020201941036738</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                </div>
                <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">An Indian patent has granted to Nagendra Babu Samineni, Dr.Dasarath Yada, Former Director,DRDL and Prof.G.Amba Prasad Rao have been granted on A REGENERATIVE STORAGE HEATER FOR HEATING HIGH PRESSURE AIR, Patent No. 461537, Application No.201811037009</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                </div>
                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">Shirish H. Sonawane, K S Rajmohan, Sivaprakash S have been granted on Microreactor design for the continuous production of microspheres, patent no. 386412-001[Design Patent], dated: 14-08-2024.</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>
                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">Prof.N.V.Ramana Rao (Inventors:1.Prof.C.B.Kameswara Rao,2.D.Rama Seshu,3.Prof.T.D.Gunneswara Rao,4.Chintamanjula) have been granted on PREFABRICATED MESH AS LONGITUDINAL CORE REINFORCEMENT IN REINFORCED CONCRETE MEMBERS, patent no. 202041003566, dated: 14-06-2024.</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>
                    

                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title"> An Indian patent has granted to P. Abdul Azeem, P Srinath, K. Venugopal Reddy,  S. Rajkumar,  M Bamanandam, M Krishnam Raju, Sushil Patel have been granted on CALCIUM SILICATE BASED NANO CRYSTALLINE CERAMICS: A COST-EFFECTIVE METHOD FOR THE PREPARATION USING NATURAL RESOURCES, Grant No. 376586   Application No. 2020201941036738</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>


                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title"> Prof. V. Rama and Prof. C. B. Rama Rao have been granted a patent on A Tele -Health Care System and Method for Early Disease Detection bearing patent no. 2021105599, date: 03-11-2021.</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>



                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title"> Rama Krishna Varanasi, Sh.Venkata Satya Ganesh Kumar Pakki, Sh.Sankara Rao Challa, Dr.Suryanarayana Cheepurupalli, Prof P.Bangaru Babu have been granted on A Marine Propelier, patent no. 2791969,2024.</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>


                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">Dr.Gaurav kumar sharma, Shiva Ram Burra, anadh AL.Routh Anjinivas,Arvind Yadav, Prasnth anad kumar Lam have been granted  on Kids friendly spectacles, patent no. 421029-001, dated: 30-07-2024. </h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>


                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">Prof.Hari Kumar V, Chatrathi satya Krishna Tejaswi, Diwakar Kant, Dasharath Harsha Vardhan, Dumpala Pavan sai, Harshitha Agrawal, Gaurav kumar sharma, shivaraman have been granted on Multi-functional Pepper Spray, patent no. 411605-001, 16.05.2024. </h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>

                    <div className="scroll-card-wrapper flex newmarquee">
                        <div className="row text-center">
                            <div className="col-12" ><a href="#" className="read-more"><h4 className="news-title">Prof.V.Harikumar ,Dharavath Krishna, Jatoth Shivaram, Karthik Balasubramanyan have been granted on crop protection gun,patent no. 400692-001(design).</h4> </a></div><div className="col-12"><p className="read-mores"></p>
                            </div></div>
                    </div>



                </div>
            </div>

        </div>
    )
}

export default Patents
