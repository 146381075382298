import React from 'react'

const RandDsection = () => {
  return (
    <div>

    <div id="carouselRandD" className="carousel slide " data-bs-ride="carousel" data-bs-interval="3000">
   <div className="carousel-inner">
     <div className="carousel-item active">
       <img className="img-fluid" src="images/1.jpg" alt=""/>
     </div>
     <div className="carousel-item">
       <img className="img-fluid" src="images/2.jpg" alt=""/>
     </div>
     <div className="carousel-item">
       <img className="img-fluid" src="images/3.jpg" alt=""/>
     </div>
     <div className="carousel-item">
       <img className="img-fluid" src="images/4.jpg" alt=""/>
     </div>


   </div>



   <button class="carousel-control-prev" type="button" data-bs-target="#carouselRandD" data-bs-slide="prev">
    <i class="fa fa-chevron-circle-left fa-2x" aria-hidden="true" style={{color:"gray"}}></i>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselRandD" data-bs-slide="next">
    <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true" style={{color:"gray"}}></i>
        <span class="visually-hidden">Next</span>
    </button>

    
  </div>
      
    </div>
  )
}

export default RandDsection
