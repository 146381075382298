import React from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name

const Resultpagephddec2024 = () => {
    return (

        <div class="container">

            <div class="row">

                <p>



                    <h4 align='center'>
                        Ph.D. ADMISSIONS: DECEMBER - 2024 SESSION
                    </h4>
                    <h5 align='center'><u>
                        LIST OF PROVISIONALLY SELECTED CANDIDATES</u>
                    </h5>
                    <p>
                        The interviews for the shortlisted candidates were conducted during <b>11 – 14 November, 2024</b>. Based on overall performance, the following candidates are provisionally selected for Ph.D. admissions of December 2024 session. <b>At the end of the list, the instructions to be followed by the candidates are given. The candidates are informed to follow the instructions given therein strictly</b>.
                    </p>
                    <h5><b>Department of Civil Engineering:</b></h5>
                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24101012</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24101018</td><td>Institute Fellowship</td></tr>
                            <tr><td>3</td><td>PH24101029</td><td>Institute Fellowship</td></tr>
                            <tr><td>4</td><td>PH24101035</td><td>Institute Fellowship</td></tr>
                            <tr><td>5</td><td>PH24101038</td><td>Under Project</td></tr>
                            <tr><td>6</td><td>PH24101008</td><td>Under Project</td></tr>
                            <tr><td>7</td><td>PH24101001</td><td>Part Time</td></tr>
                            <tr><td>8</td><td>PH24101002</td><td>Part Time</td></tr>
                            <tr><td>9</td><td>PH24101007</td><td>Part Time</td></tr>
                            <tr><td>10</td><td>PH24101011</td><td>Part Time</td></tr>
                            <tr><td>11</td><td>PH24101019</td><td>Part Time</td></tr>
                            <tr><td>12</td><td>PH24101020</td><td>Part Time</td></tr>
                            <tr><td>13</td><td>PH24101021</td><td>Part Time</td></tr>
                            <tr><td>14</td><td>PH24101026</td><td>Part Time</td></tr>
                            <tr><td>15</td><td>PH24101031</td><td>Part Time</td></tr>
                            <tr><td>16</td><td>PH24101039</td><td>Part Time</td></tr>
                            <tr><td>17</td><td>PH24101040</td><td>Part Time</td></tr>
                            <tr><td>18</td><td>PH24101044</td><td>Part Time</td></tr>
                            <tr><td>19</td><td>PH24101047</td><td>Part Time</td></tr>
                            <tr><td>20</td><td>PH24101049</td><td>Part Time</td></tr>
                            <tr><td>21</td><td>PH24101050</td><td>Part Time</td></tr>
                            <tr><td>22</td><td>PH24101051</td><td>Part Time</td></tr>
                            <tr><td>23</td><td>PH24101052</td><td>Part Time</td></tr>

                        </tbody>
                    </table>


                    <h5><b>Department of Electrical Engineering:</b></h5>


                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24102002</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24102012</td><td>Self-Finance</td></tr>
                            <tr><td>3</td><td>PH24102009</td><td>Part Time</td></tr>
                            <tr><td>4</td><td>PH24102010</td><td>Part Time</td></tr>

                        </tbody>
                    </table>

                    <h5><b>Department of Mechanical Engineering:</b></h5>

                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24103018</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24103025</td><td>Institute Fellowship</td></tr>
                            <tr><td>3</td><td>PH24103030</td><td>Institute Fellowship</td></tr>
                            <tr><td>4</td><td>PH24103005</td><td>Under Project</td></tr>
                            <tr><td>5</td><td>PH24103011</td><td>Under Project</td></tr>
                            <tr><td>6</td><td>PH24103022</td><td>Under Project</td></tr>
                            <tr><td>7</td><td>PH24103026</td><td>Full-Time - External-R&D</td></tr>
                            <tr><td>8</td><td>PH24103002</td><td>Part Time</td></tr>
                            <tr><td>9</td><td>PH24103007</td><td>Part Time</td></tr>
                            <tr><td>10</td><td>PH24103008</td><td>Part Time</td></tr>
                            <tr><td>11</td><td>PH24103017</td><td>Part Time</td></tr>
                            <tr><td>12</td><td>PH24103027</td><td>Part Time</td></tr>
                            <tr><td>13</td><td>PH24103034</td><td>Part Time</td></tr>
                            <tr><td>14</td><td>PH24103038</td><td>Part Time</td></tr>

                        </tbody>
                    </table>


                    <h5><b>Department of Electronics and Communications Engineering:</b></h5>



                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24104057</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24104021</td><td>Under Project</td></tr>
                            <tr><td>3</td><td>PH24104045</td><td>Under Project</td></tr>
                            <tr><td>4</td><td>PH24104002</td><td>Part Time</td></tr>
                            <tr><td>5</td><td>PH24104005</td><td>Part Time</td></tr>
                            <tr><td>6</td><td>PH24104008</td><td>Part Time</td></tr>
                            <tr><td>7</td><td>PH24104009</td><td>Part Time</td></tr>
                            <tr><td>8</td><td>PH24104011</td><td>Part Time</td></tr>
                            <tr><td>9</td><td>PH24104012</td><td>Part Time</td></tr>
                            <tr><td>10</td><td>PH24104014</td><td>Part Time</td></tr>
                            <tr><td>11</td><td>PH24104028</td><td>Part Time</td></tr>
                            <tr><td>12</td><td>PH24104031</td><td>Part Time</td></tr>
                            <tr><td>13</td><td>PH24104034</td><td>Part Time</td></tr>
                            <tr><td>14</td><td>PH24104037</td><td>Part Time</td></tr>
                            <tr><td>15</td><td>PH24104042</td><td>Part Time</td></tr>
                            <tr><td>16</td><td>PH24104044</td><td>Part Time</td></tr>
                            <tr><td>17</td><td>PH24104047</td><td>Part Time</td></tr>
                            <tr><td>18</td><td>PH24104048</td><td>Part Time</td></tr>
                            <tr><td>19</td><td>PH24104051</td><td>Part Time</td></tr>
                            <tr><td>20</td><td>PH24104052</td><td>Part Time</td></tr>
                            <tr><td>21</td><td>PH24104054</td><td>Part Time</td></tr>
                            <tr><td>22</td><td>PH24104058</td><td>Part Time</td></tr>
                            <tr><td>23</td><td>PH24104061</td><td>Part Time</td></tr>
                            <tr><td>24</td><td>PH24104066</td><td>Part Time</td></tr>
                            <tr><td>25</td><td>PH24104071</td><td>Part Time</td></tr>
                            <tr><td>26</td><td>PH24104077</td><td>Part Time</td></tr>

                        </tbody>
                    </table>




                    <h5><b>Department of Metallurgical and Materials Engineering:</b></h5>

                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24105004</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24105002</td><td>External-ARCI</td></tr>
                            <tr><td>3</td><td>PH24105006</td><td>External-ARCI</td></tr>
                            <tr><td>4</td><td>PH24105007</td><td>External-ARCI</td></tr>
                            <tr><td>5</td><td>PH24105001</td><td>Part Time</td></tr>

                        </tbody>
                    </table>



                    <h5><b>Department of Chemical Engineering:</b></h5>



                    <table className="table table-bordered">
                        <tbody>
                       
                        <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                        <tr><td>1</td><td>PH24106004</td><td>Institute Fellowship</td></tr>
                        <tr><td>2</td><td>PH24106016</td><td>Institute Fellowship</td></tr>
                        <tr><td>3</td><td>PH24106005</td><td>Project</td></tr>
                        <tr><td>4</td><td>PH24106009</td><td>Project</td></tr>
                        <tr><td>5</td><td>PH24106010</td><td>Project</td></tr>
                        <tr><td>6</td><td>PH24106012</td><td>Project</td></tr>
                        <tr><td>7</td><td>PH24106015</td><td>Project</td></tr>
                        <tr><td>8</td><td>PH24106001</td><td>Part Time</td></tr>
                        <tr><td>9</td><td>PH24106013</td><td>Part Time</td></tr> </tbody>
                    </table>



                    <h5><b>Department of Computer Science and Engineering:</b></h5>

                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24107042</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24107130</td><td>Self-Finance</td></tr>
                            <tr><td>3</td><td>PH24107017</td><td>Part Time</td></tr>
                            <tr><td>4</td><td>PH24107018</td><td>Part Time</td></tr>
                            <tr><td>5</td><td>PH24107025</td><td>Part Time</td></tr>
                            <tr><td>6</td><td>PH24107028</td><td>Part Time</td></tr>
                            <tr><td>7</td><td>PH24107037</td><td>Part Time</td></tr>
                            <tr><td>8</td><td>PH24107039</td><td>Part Time</td></tr>
                            <tr><td>9</td><td>PH24107044</td><td>Part Time</td></tr>
                            <tr><td>10</td><td>PH24107054</td><td>Part Time</td></tr>
                            <tr><td>11</td><td>PH24107063</td><td>Part Time</td></tr>
                            <tr><td>12</td><td>PH24107065</td><td>Part Time</td></tr>
                            <tr><td>13</td><td>PH24107086</td><td>Part Time</td></tr>
                            <tr><td>14</td><td>PH24107089</td><td>Part Time</td></tr>
                            <tr><td>15</td><td>PH24107095</td><td>Part Time</td></tr>
                            <tr><td>16</td><td>PH24107105</td><td>Part Time</td></tr>
                            <tr><td>17</td><td>PH24107123</td><td>Part Time</td></tr>
                            <tr><td>18</td><td>PH24107136</td><td>Part Time</td></tr>
                            <tr><td>19</td><td>PH24107151</td><td>Part Time</td></tr>
                            <tr><td>20</td><td>PH24107162</td><td>Part Time</td></tr>
                            <tr><td>21</td><td>PH24107164</td><td>Part Time</td></tr>
                            <tr><td>22</td><td>PH24107166</td><td>Part Time</td></tr>
                            <tr><td>23</td><td>PH24107173</td><td>Part Time</td></tr>
                            <tr><td>24</td><td>PH24107174</td><td>Part Time</td></tr>


                        </tbody>
                    </table>





                    <h5><b>Department of Biotechnology:</b></h5>


                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24108010</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24108014</td><td>Institute Fellowship</td></tr>
                            <tr><td>3</td><td>PH24108019</td><td>Institute Fellowship</td></tr>
                            <tr><td>4</td><td>PH24108027</td><td>Institute Fellowship</td></tr>
                            <tr><td>5</td><td>PH24108029</td><td>Institute Fellowship</td></tr>
                            <tr><td>6</td><td>PH24108008</td><td>Under Project</td></tr>
                            <tr><td>7</td><td>PH24108015</td><td>Part Time</td></tr>
                            <tr><td>8</td><td>PH24108016</td><td>Part Time</td></tr>
                            <tr><td>9</td><td>PH24108017</td><td>Part Time</td></tr>
                            <tr><td>10</td><td>PH24108018</td><td>Part Time</td></tr>
                            <tr><td>11</td><td>PH24108020</td><td>Part Time</td></tr>

                        </tbody>
                    </table>




                    <h5><b>Department of Mathematics:</b></h5>



                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24111002</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24111008</td><td>Institute Fellowship</td></tr>
                            <tr><td>3</td><td>PH24111012</td><td>Part Time</td></tr>
                            <tr><td>4</td><td>PH24111013</td><td>Part Time</td></tr>
                            <tr><td>5</td><td>PH24111015</td><td>Part Time</td></tr>

                        </tbody>
                    </table>



                    <h5><b>Department of Physics:</b></h5>



                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24109009</td><td>Institute Fellowship</td></tr>
                        </tbody> </table>



                    <h5><b>Department of Chemistry:</b></h5>






                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24110009</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24110021</td><td>Institute Fellowship</td></tr>
                            <tr><td>3</td><td>PH24110006</td><td>R&D Project</td></tr>
                            <tr><td>4</td><td>PH24110007</td><td>R&D Project</td></tr>
                            <tr><td>5</td><td>PH24110003</td><td>Part Time</td></tr>
                            <tr><td>6</td><td>PH24110008</td><td>Part Time</td></tr>
                            <tr><td>7</td><td>PH24110015</td><td>Part Time</td></tr>
                            <tr><td>8</td><td>PH24110022</td><td>Part Time</td></tr>

                        </tbody> </table>






                    <h5><b>Department of Management Studies:</b></h5>


                    <table className="table table-bordered">
                        <tbody>
                            <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                            <tr><td>1</td><td>PH24113021</td><td>Institute Fellowship</td></tr>
                            <tr><td>2</td><td>PH24113023</td><td>Institute Fellowship</td></tr>
                            <tr><td>3</td><td>PH24113024</td><td>Institute Fellowship</td></tr>
                            <tr><td>4</td><td>PH24113026</td><td>Institute Fellowship</td></tr>
                            <tr><td>5</td><td>PH24113001</td><td>UGC-NET-NFSC</td></tr>
                            <tr><td>6</td><td>PH24113027</td><td>Self-Finance</td></tr>
                            <tr><td>7</td><td>PH24113002</td><td>Part Time</td></tr>
                            <tr><td>8</td><td>PH24113016</td><td>Part Time</td></tr>
                            <tr><td>9</td><td>PH24113019</td><td>Part Time</td></tr>

                        </tbody></table>


                    <h5><b>Department of Humanities and Social Sciences:</b></h5>

                    <table className="table table-bordered"> <tbody>
                    <tr><td>Sl. No.</td><td>Application ID</td><td>Type of Selection</td></tr>
                    <tr><td>N I L</td><td>N I L</td><td>N I L</td></tr>


                    </tbody> </table>


                    <ol>
                        <li><b>INSTRUCTIONS FOR PHYSICAL REPORTING, FEE PAYMENT DETAILS AND OTHER NECESSARY INSTRUCTIONS WILL BE PUBLISHED SHORTLY IN THE INSTITUTE WEBSITE</b></li>

                    </ol>




                 
                    <br></br>
                    <br></br>
                    <b>Associate Dean Admissions      </b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>DEAN, ACADEMIC</b>
                    <br></br>
                    <br></br>
                </p>

            </div>
        </div>
    );
};

export default Resultpagephddec2024;