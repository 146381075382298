import { useState } from "react";
import '../css/cseDeptStyle.css';
const EEEDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/EED_banner_2023-10-4-13-12-46.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>
                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>
                            </div>
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ee-nvs"
                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Srikanth_N_V_2024-3-7-17-38-37.jpg" alt="" className="img-fluid" width="190" height="220" />
                                <h6>Prof. N V Srikanth</h6>
                            </a>
                                <p className="justified-text"> Head of the Department <br /> <a href="mailto:eee_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>eee_hod@nitw.ac.in</a> <br /> 9490165342 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        <p className="justified-text">
                                            The Department of Electrical Engineering is one of the oldest departments of the National Institute of Technology, Warangal (NITW). Established as one of the major departments of the Institute, in 1959, the department has been actively engaged in teaching and research in diverse fields of Electrical Engineering. With excellent faculty, the department offers Under Graduate (B.Tech) program in Electrical and Electronics Engineering and Post Graduate (M.Tech) programmes in “Power Electronics & Drives”, “Power Systems Engineering” ,“Smart Electric Grid” and also offers Ph.D. programme in Electrical Engineering.
                                        </p>

                                        <p className="justified-text">

                                            The department has well-equipped state-of-the-art laboratories to augment the coursework and enhance the research potentials. The department has a dynamic group of faculties with profound experience in academics, research and industry, dedicated in teaching-learning process and actively engaged in the cutting-edge R&D activities with broad areas of expertise like; Power Electronic & Drives, Application of Power Electronics to Energy Efficient Lighting Systems, DSP controlled Industrial Drives, Electric Vehicle & Wireless Power Transfer and Power Quality Improvement, State Estimation and Real Time Control of Power Systems, Applications of ANN and Fuzzy Logic in Power Systems, Power System Deregulation, Power System Transients, Artificial Intelligence & Machine Learning etc.
                                        </p>
                                        <p className="justified-text">
                                            The department is strongly supported by external research projects and training programmes funded by Government of India through various prestigious schemes like; IMPRINT-India, SPARC, DST, SERB, CPRI, DRDO, MNRE, MeitY, GIAN, AICTE-ATAL, E&ICT, PMMMNMTT etc.
                                        </p>

                                        <p className="justified-text">
                                            The department has strong Industry interaction and has been involved in constantly handling various consultancy projects for various Industries and Government of Telangana and Andhra Pradesh. It has an MOU with Hitachi Energy India, Defence Research and Development Organization (DRDO), Central Power Research Institute (CPRI), POWERGRID, Grid India to carry out the collaborative projects.
                                        </p>

                                        <p className="justified-text">
                                            Throughout the department’s history, its faculty, students, and alumni have made major contributions and advancement in the fields of academia, industry, research and development.
                                        </p>

                                        <img src="https://nitw.ac.in/api/static/files/Electrical_Engineering_:_History_2023-10-12-16-19-16.png" alt="" className="img-fluid mb-5" />
                                    </div>
                                                                        {/* NUMBERING START*/}
                                                                        <div class="row nirf-ranking-counter text-center">
                         
                         
                        
                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>31</span></h2>
                                 
                                 <h6 class="mt-0"><b>Faculty</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>13</span></h2>
                                 <h6 class="mt-0"><b>Staff</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>63</span></h2>
                                 <h6 class="mt-0"><b>PhD Research Scholars</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>576</span></h2>
                                 <h6 class="mt-0"><b>Undergraduate Students</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>237</span></h2>
                                 <h6 class="mt-0"><b>Postgraduate Students</b></h6>
                             </div>
                         </div>



                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>428</span></h2>
                                 <h6 class="mt-0"><b>Publications</b></h6>
                             </div>
                         </div>

                       
                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>
                                            <p className="  mb-4">To be recognized as an  excellent knowledge Hub for high quality educational programs, research and consultancy  in tune with the vision of the Institute.
                                            </p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                                                className="img-fluid" />
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">
                                                To create high quality Educational environment in which students are prepared to meet the challenges of modern Electrotechnical industry to fulfill the societal aspirations, by equipping them with<br></br>

                                                - Analytical and practical skills<br></br>

                                                - Management competence<br></br>
                                                - Innovative and creative approach to work</p>
                                            <p className="  mb-2">    To strengthen interaction with industry through lasting partnership focusing on <br></br>


                                                - Curriculum development<br></br>
                                                - Research and Consultancy
                                                <br></br>
                                                - Continuing Education Programs

                                            </p>


                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardEE/ElectricalEngineering"> Notices/Announcements</a>
                                        </h4>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                                    {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div className="row">


                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Courses Offered</h3>
                                                    </div>
                                                    <p className="justified-text">The department offers B.Tech in Electrical and Electronics Engineering with an intake of 175 students and M.Tech programme in three streams namely Power Systems Engineering,
                                                     Power Electronics & Drives and Smart Electric Grid with an annual intake of 32 Students, 33 Students and 30 Students respectively.</p>
                                                     <p className="justified-text">In addition to these programmes the department also offers Ph.D programme in all the areas of Electrical Engineering with 50 scholars pursuing their doctoral studies currently and 68 Doctorate degrees are awarded as on June 2020.</p>
                                                     <p className="justified-text">The department has also introduced courses on Artificial Intelligence Techniques in Power System and Power System Deregulation at M.Tech level for the first time in the state of Andhra Pradesh/Telangana.</p>


                                              
                                                   
                                                </div>
                                            </div>
                                        </div> */}






                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>B. Tech </h3>




                                                    </div>

                                                    <h5>Honors Programs </h5>
                                                    <p className="justified-text">
                                                        Honors is an additional credential a student may earn if she/he opts for the extra 18 credits
                                                        needed for this in her/his own discipline. The concerned department specifies the set of courses
                                                        for earning Honors. Honors is not indicative of class.<br></br>
                                                        Students with a minimum of 8.00 CGPA without any “F” Grade / backlog are only allowed to
                                                        register for Honors program offered by that Department.<br></br>
                                                        Students, satisfying the eligibility requirements may be permitted to do one minor/one honours
                                                        or two minors or one minor and one Honours.<br></br>
                                                        Number of credits for Honors program is 18 with 6 courses or (5 courses + 1 project for 3
                                                        credits) as prescribed by that Department.
                                                        <br></br>
                                                        Courses for Honors Program start from 5thSemester and the student is required to plan
                                                        registration for Honors courses, in order to complete all the six courses by the end of eighth
                                                        semester.
                                                        <br></br>
                                                        Students registering for Honors program shall be prepared to write more than one exam in a day.
                                                        <br></br>
                                                        After successful completion of the Honors program, the student will be awarded a degree in
                                                        “name of the discipline” with “Honors”.
                                                    </p>

                                                    <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2022/02/15/honors_electrical-and-electronics-engineering.pdf"
                                                        target="_blank">Honors Programs Scheme and Syllabus     <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                    <h5>Minor Programs </h5>

                                                    <p className="justified-text">
                                                        Minor program is an additional credential a student may earn if she/he does 18 credits worth of
                                                        additional courses in a discipline other than her/his major discipline of B.Tech. degree. All
                                                        academic departments in the Institute offer minors in their disciplines, and will prescribe a
                                                        specific set of courses and/or projects necessary for earning a minor in that discipline.

                                                        <br></br>
                                                        Students with a minimum of 7.00 CGPA without any “F” Grade / backlog are only allowed to
                                                        register for Minor program offered by any other Department.
                                                        <br></br>
                                                        Students, satisfying the eligibility requirements may be permitted to do one minor/one honours
                                                        or two minors or one minor and one Honours.
                                                        <br></br>
                                                        Number of credits for earning Minor specialization is 18 with 6 courses or (5 courses + 1 project
                                                        for 3 credits), as prescribed by that Department.
                                                        <br></br>
                                                        Courses for Minor specialization start from 3rd Semester and the student is required to register
                                                        for one minor specialization course in every semester.
                                                        <br></br>
                                                        Minimum and maximum number of students for any Minor specialization is 10 and 60
                                                        respectively
                                                        <br></br>
                                                        Students registering for Minor program shall be prepared to write more than one exam in a day
                                                        <br></br>
                                                        After successful completion of the Minor specialization requirements, the student will be
                                                        awarded a degree in “name of the discipline” with minor specialization in “name of the minor
                                                        specialization”.


                                                    </p>


                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Minor_Programs_2022-7-29-12-26-12.pdf"
                                                        target="_blank">Minor Programs Scheme and Syllabus    <i
                                                            className="fa fa-angle-right"></i> </a> </p>





                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="card-4">
                                                        <div className="card-detail">
                                                            <div className="head-bx">

                                                                <h5>Open Elective Courses </h5>
                                                                <p className="justified-text">
                                                                    Open Elective Courses can be in any of the following areas: Basic Sciences, Engineering Science
                                                                    Courses, Humanities & Social Sciences and Management.
                                                                    <br></br>
                                                                    A student will not be permitted to register open elective course offered by the parent department.
                                                                    <br></br>
                                                                    The allotment of the open elective course shall be based on CGPA and the preference of the
                                                                    student.
                                                                    <br></br>
                                                                    Open Elective Courses list
                                                                </p>


                                                                <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/11/12/open-elective-1_vi-sem-1.pdf"
                                                                    target="_blank">Open Elective - I VI Semester Scheme and Syllabus     <i
                                                                        className="fa fa-angle-right"></i> </a> </p>


                                                                <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/11/12/open-elective-2_vii-sem.pdf"
                                                                    target="_blank">Open Elective - II VII Semester Scheme and Syllabus     <i
                                                                        className="fa fa-angle-right"></i> </a> </p>

                                                                <h3>B.Tech  </h3>



                                                                <p className="more"> <a className="more-info-link"
                                                                    href="https://nitw.ac.in/api/static/files/Electrical_Engineering_B.Tech_v1_2022-10-3-15-37-5.pdf"
                                                                    target="_blank">UG Scheme and Syllabus (w.e.f. 2021-22)<i className="fa fa-angle-right"></i> </a> </p>

                                                                <p className="more"> <a className="more-info-link"
                                                               
                                                                    href="https://nitw.ac.in/api/static/files/B.Tech_Electrical_and_Electronics_Engineering-Curriculum-new-2024-25_2024-7-19-15-44-57.pdf"
                                                                    target="_blank">UG Scheme and Syllabus (w.e.f. 2024-25)<i className="fa fa-angle-right"></i> </a> </p>
                                                            </div>





                                                        </div>
                                                    </div>

                                                    <div className="head-bx">

                                                        <h3>M.Tech </h3>
                                                        <h5>Power Electronics and Drives  </h5>

                                                    </div>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/MTech-PED-complete_Syllabi_2022-10-11-8-36-32.pdf"
                                                        target="_blank">PED Scheme and Syllabus (w.e.f. 2021-22)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/1_M.Tech_Power_Electronics_and_Drives-curriculum-new-2024-25_2024-7-19-16-39-58.pdf"
                                                        target="_blank">PED Scheme and Syllabus (w.e.f. 2024-25)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <h5>Power Systems Engineering</h5>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/MTech-PSE-Complete_Syllabi_2022-10-11-8-37-31.pdf"
                                                        target="_blank">PSE Scheme and Syllabus (w.e.f. 2021-22)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/2_M.Tech_Power_Systems_Engineering-curriculum-new-2024-25_2024-7-19-16-40-41.pdf"
                                                        target="_blank">PSE Scheme and Syllabus (w.e.f. 2024-25)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <h5>Smart Electric Grid </h5>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/MTech-SEG-Complete_Syllabi_2022-10-11-8-38-21.pdf"
                                                        target="_blank">SEG Scheme and Syllabus (w.e.f. 2021-22)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/3_M.Tech_Smart_Electric_Grid-curriculum-new-2024-25_2024-7-19-16-41-29.pdf"
                                                        target="_blank">SEG Scheme and Syllabus (w.e.f. 2024-25)    <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                    <h5>Control and Automation </h5>

                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/4_M.Tech_Control_and_Automation-curriculum-new-2024-25_2024_2024-7-19-16-42-17.pdf"
                                                        target="_blank">CA Scheme and Syllabus (w.e.f. 2024-25)     <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                </div>
                                            </div>





                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D </h3>

                                                        <p className="more"> <a className="buttton more-info-link"
                                                            href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                            target="_blank"> Rules and Regulations of Ph.D. Program (w.e.f. 2021-22) <i
                                                                className="fa fa-angle-right"></i> </a> </p>
                                                         <p className="more"> <a className="buttton more-info-link"
                                                            href="https://nitw.ac.in/api/static/files/PhD_Regulations_(2024-25)_2024-7-17-11-38-22.pdf"
                                                            target="_blank"> Rules and Regulations of Ph.D. Program (w.e.f. 2024-25) <i
                                                                className="fa fa-angle-right"></i> </a> </p>
                                                    </div>





                                                </div>
                                            </div>





                                        </div>











                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D.</h3>
                                                     <h5>Total number  of Ph.D. degrees awarded : 74</h5>
                                                     
                                                     <h5>Total number of Ph.D. scholars ongoing  : 61</h5>
                                                        <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Ph.D._Degree_Awarded_2022-7-29-12-43-13.pdf"
                                                        target="_blank">List of Ph.D. Degree Awarded    <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                              <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/phd_ongoing_2022-7-29-12-59-4.pdf"
                                                        target="_blank">List of Ph.D. Scholars:- Ongoing    <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                      
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div className="row laboratory-facilities">
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                {/* <h3 className="">Electrical Machines Lab</h3> */}

                                                <img src="https://nitw.ac.in/api/static/files/Laboratories_EED_1_2023-10-12-16-29-40.png" alt="" className="img-fluid mb-5" />
                                                <img src="https://nitw.ac.in/api/static/files/Laboratories_EED_2_2023-10-12-16-30-34.png" alt="" className="img-fluid mb-5" />

                                                {/* <h3 className=""></h3> */}
                                            </header>
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 Small Form Factor
                                                                Systemsi7 @3.40GHz, RAM 4GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                                                            <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 5</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2"> Software</h4>
                                                         <p className="justified-text">Dual boot with Windows 7 and Ubuntu 14.04, NetBeans 7, MSDN, Dev C++
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/comp-laboratory.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Data Engineering Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP EliteDesk 800 g1 SFF Systems i7 @3.40GHz,
                                                                4GB RAM, 500GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                         <p className="justified-text">Cognos BIClementine Version 11.0, Oracle 11g (Unlimited user
                                                            Licenses), MSDN Academic Alliance Software, Informatica (25 user
                                                            licenses )</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/data-eng.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div> */}







                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">


                                            <section id="profile-info">

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ee-kiruba81 "
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._A._Kirubakaran_2024-3-7-17-4-33.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-kiruba81 "
                                                                        target="_blank">Prof. A. Kirubakaran</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9603722359 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kiruba81@nitw.ac.in ">kiruba81@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">Power Electronics-DC-DC Converters, DC-AC Inverters, Multilevel Inverters, Power Quality and Grid interface, Renewable Energy and Distributed Generation.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ee-giridhar"
                                                            target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._A._V._Giridhar_2024-3-7-17-6-11.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-giridhar"
                                                                        target="_blank">Prof. A. V. Giridhar</a>
                                                                    </h5> <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9000742219 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:giridhar@nitw.ac.in">giridhar@nitw.ac.in   </a>
                                                                    </p>
                                                                    <p className="justified-text"> High Voltage Engineering, Condition Monitoring & Diagnosis of Power Equipments, Pulsed Power Technology, Nano Dielectrics.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ee-altafbadar"
                                                            target="_blank" className="img-fluid">
                                                            <img src="https://nitw.ac.in/api/static/files/Prof._Altaf_Q_H_Badar_2024-3-7-17-7-56.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-altafbadar"
                                                                        target="_blank">Prof. Altaf Q H Badar</a> </h5>
                                                                    <span>Assistant Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9890068893 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:altafbadar@nitw.ac.in  ">altafbadar@nitw.ac.in  </a>
                                                                    </p>
                                                                    <p className="justified-text">Artificial Intelligence Applications to Power Systems, Energy Management Systems, Energy Trading</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href=" https://erp.nitw.ac.in/ext/profile/ee-bkmurthy"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Bhagwan_Krishna_Murthy_2024-3-7-17-9-34.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href=" https://erp.nitw.ac.in/ext/profile/ee-bkmurthy"
                                                                            target="_blank">Prof. Bhagwan Krishna Murthy</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9848995181 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:bkmurthy@nitw.ac.in  ">bkmurthy@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">Wind and wave energy; Doubly fed induction machine; Power electronic controls for electrical drives; Digital Signal Processing</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-nagubhukya"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Bhookya_Nagu_2024-3-7-17-10-43.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-nagubhukya"
                                                                            target="_blank">Prof. Bhookya Nagu</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i>8332969287 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:nagubhukya@nitw.ac.in  ">nagubhukya@nitw.ac.in </a> </p>
                                                                        <p className="justified-text"> Solar PV , Fuel Cell , Wind Power, Smart Grid Technologies, Power System Stability, HVDC and AI Techniques...
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17098"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17098/17098.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17098"
                                                                            target="_blank">Prof. Bidyadhar Subudhi</a> </h5>
                                                                        <span>Professor (HAG) and Director, NITW</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7008118559 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:bidyadhar@nitw.ac.in ">bidyadhar@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">-</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-venugopalreddy"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._B._Venugopal_Reddy_2024-3-7-17-13-5.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-venugopalreddy"
                                                                            target="_blank">Prof. B. Venugopal Reddy</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info">
                                                                            <i className="fa fa-mobile fa-lg"
                                                                                aria-hidden="true"></i> 9404527890 <br /> <i
                                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                        href="mailto:venugopalreddy@nitw.ac.in ">venugopalreddy@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> Multilevel Inverters, Renewable energy systems,Open-end Winding Induction Motor Drives.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href=" https://erp.nitw.ac.in/ext/profile/ee-rnitchinthamalla"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Ch._Ramulu_2024-3-7-17-14-17.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href=" https://erp.nitw.ac.in/ext/profile/ee-rnitchinthamalla"
                                                                            target="_blank">Prof. Ch. Ramulu</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9866561691,8332969291 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:rnitchinthamalla@nitw.ac.in ">rnitchinthamalla@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power electronics and drives, application of power electronics to nonconventional energy conditioning, AC-DC electrolytic capacitor-less single-phase LED drivers, and high gain DC-DC Converters.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-ch.venkaiah"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Chintham_Venkaiah_2024-3-7-17-15-29.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-ch.venkaiah"
                                                                            target="_blank">Prof. Chintham Venkaiah</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969285 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ch.venkaiah@nitw.ac.in ">ch.venkaiah@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text">AI Technique applications to Power and Energy Systems;Power System Deregulation and Restructuring; Distribution Generation Technology; Economics and Financing of Renewable Energy Technologies;Power Procurement Strategy and Power Exchanges;Smart Grid Technologies;ICT applications to Power and Energy Systems;Effectual Entrepreneurship and Technology based Entrepreneurship Development.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-dpanda"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Debasmita_Panda_2024-3-7-17-16-48.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-dpanda"
                                                                            target="_blank">Prof. Debasmita Panda</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7899852334 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:dpanda@nitw.ac.in ">dpanda@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Restructured Power Systems, Power Market Risk Management, Portfolio Optimization, Energy Trading in Local Energy Market, Transactive Energy Market.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-srinudee"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._D_Sreenivasarao_2024-3-7-17-18-5.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-srinudee"
                                                                            target="_blank">Prof. D Sreenivasarao</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7842001002 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:srinudee@nitw.ac.in ">srinudee@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power quality, Custom Power Devices (CPDs), Flexible AC Transmission System (FACTS), Multilevel inverters, Improved power quality converters.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-gsivakumar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._G._Siva_Kumar_2024-3-7-17-19-15.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-gsivakumar"
                                                                            target="_blank">Prof. G. Siva Kumar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7702716890 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:gsivakumar@nitw.ac.in ">gsivakumar@nitw.ac.in
                                                                            </a> </p>
                                                                        <p className="justified-text"> Power electronics application to the power system, renewable system, and AC microgrid.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-anilnaik205"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Kanasottu_Anil_Naik_2024-3-7-17-20-12.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-anilnaik205"
                                                                            target="_blank">Prof. Kanasottu Anil Naik</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8630384437, 9690314482 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:anilnaik205@nitw.ac.in ">anilnaik205@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Wind energy system grid integration issues; Partial shading in PV solar; intelligent controller applications in power system; and Micro-grid stability and control. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-kiranr"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/kiran_2024-12-12-10-13-15.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-kiranr"
                                                                            target="_blank">Prof. Kiran R</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9449385579 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kiranr@nitw.ac.in ">kiranr@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Power Electronics Applications to Power Systems, Control application in power electronics, DC - DC converter, Battery Management Systems</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-mnalam"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Mahamad_Nabab_Alam_2024-3-7-17-22-16.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-mnalam"
                                                                            target="_blank">Prof. Mahamad Nabab Alam</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i>9897998728<br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:mnalam@nitw.ac.in"> mnalam@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">Protection coordination, power system protection and optimization, adaptive protection using phasor measurement units, networked microgrids analysis, protection, and optimization, distribution system state estimation, hydroelectrical engineering, distribution automation, artificial intelligence techniques, numerical and metaheuristic optimization techniques</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-ub"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._M_Udaya_Bhasker_2024-3-7-17-23-48.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-ub"
                                                                            target="_blank">Prof. M Udaya Bhasker</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702462241 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ub@nitw.ac.in   ">
                                                                                ub@nitw.ac.in    </a> </p>
                                                                        <p className="justified-text">Bi-directional DC-DC converters, Energy Storage system, Digital Control system.SCOPUS ID: 57193699704, Google Scholar ID: IXIEeysAAAAJ https://orcid.org/0000-0002-5919-4482. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-blnraju"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Narasimharaju_B._L_2024-3-7-17-25-17.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-blnraju"
                                                                            target="_blank">Prof. B. L Narasimharaju</a>
                                                                        </h5> <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 0870-246-2247 (O)
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:blnraju@nitw.ac.in"> blnraju@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">LED Drivers for Lighting Systems; Multilevel power converters; Grid integration of solar energy ; Switched Reluctance Motor drives; Active Front End Power Converters ; Open End Winding Induction Motor Drives ; Modulation and switching techniques ; AI Techniques to Control of Power Converters ;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-pmishra"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Palash_Mishra_2024-3-7-17-26-58.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-pmishra"
                                                                            target="_blank">Prof. Palash Mishra</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9566160482
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: pmishra@nitw.ac.in "> pmishra@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> High Voltage DC/AC Systems; Nano Dielectrics; Condition Monitoring of Power Apparatus; Artificial Intelligence Techniques; Polymeric Insulation. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-selvi"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Porpandiselvi_S_2024-3-7-17-28-55.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-selvi"
                                                                            target="_blank">Prof. Porpandiselvi S</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969288
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: selvi@nitw.ac.in  "> selvi@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text"> PV fed LED drivers, Induction Heating Applications, High frequency Soft-Switched Inverters. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>






                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-rkrishan"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Ram_Krishan_2024-3-7-17-30-18.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-rkrishan"
                                                                            target="_blank">Prof. Ram Krishan</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9555605636
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: rkrishan@nitw.ac.in "> rkrishan@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power system stability and Optimization, Integration of Renewable Energy Sources, Distribution system analysis, Electrical Energy Storage System. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-jey"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._R._Jeyasenthil_2024-3-7-17-32-6.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-jey"
                                                                            target="_blank">Prof. R. Jeyasenthil</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9360153302
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: jey@nitw.ac.in  "> jey@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Control System Design: Linear and Nonlinear Control; Robust Control: Quantitative Feedback Theory (QFT), H-infinity Control; Optimization-based Control.Application areas: Control of Power electronic converters and Power system, Mechatronics. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-sailaja"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16234/16234.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-sailaja"
                                                                            target="_blank">Prof. Sailaja Kumari M</a> </h5>
                                                                        <span> Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> -
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: sailaja@nitw.ac.in "> sailaja@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power Markets, Distributed Generations in Power Markets, Synchrophasor applications in Smart Electric Grids, Power System Optimization, Artificial Neural networks, Evolutionary Algorithms and applications to power systems, Grid Integration of Renewable energy sources, Energy storage systems optimization. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>






                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-drinjetisatishkumar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Satish_Kumar_I_2024-3-7-17-34-18.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-drinjetisatishkumar"
                                                                            target="_blank">Prof. Satish Kumar I</a> </h5>
                                                                        <span> Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9581371537
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: drinjetisatishkumar@nitw.ac.in"> drinjetisatishkumar@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text"> Network restructuring; optimal allocation of energy sources; economic dispatch; automatic generation control; load frequency control; the small signal stability of AC Micro Grids; Soft Computing applications to Power Systems, Electric Drives, Image and Radar Signal Processing. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-sharmili"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Sharamili_Das_2024-4-3-11-10-22.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-sharmili"
                                                                            target="_blank">Prof. Sharmili Das</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9456786514
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: sharmili@nitw.ac.in  "> sharmili@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text"> Machine Drives and Power Electronics </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-dvss"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Siva_Sarma_D_V_S_S_2024-3-7-17-35-44.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-dvss"
                                                                            target="_blank">Prof. Siva Sarma D V S S</a> </h5>
                                                                        <span> Professor (HAG)</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849434415
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: dvss@nitw.ac.in "> dvss@nitw.ac.in     </a>
                                                                        </p>
                                                                        <p className="justified-text">  Power Quality, Power System Protection, Condition Monitoring of Electrical Equipment, Switching Transients. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-sekhar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Somasekhar_V._T._2024-3-7-17-37-11.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-sekhar"
                                                                            target="_blank">Prof. Somasekhar V. T.</a> </h5>
                                                                        <span> Professor (HAG)</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462231
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: sekhar@nitw.ac.in  "> sekhar@nitw.ac.in      </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power Electrionics and Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-nvs"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Srikanth_N_V_2024-3-7-17-38-37.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-nvs"
                                                                            target="_blank">Prof. Srikanth N V</a> </h5>
                                                                        <span> Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9247881601
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: nvs@nitw.ac.in   "> nvs@nitw.ac.in       </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power System Stability and control, HVDC and FACTS, AI Techniques applications to Power Systems, Real-time control of power systems. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-ssr"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Srinivasa_Rao_Sandepudi_2024-3-7-17-40-43.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-ssr"
                                                                            target="_blank">Prof. Srinivasa Rao Sandepudi</a> </h5>
                                                                        <span> Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702462233
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: ssr@nitw.ac.in    "> ssr@nitw.ac.in       </a>
                                                                        </p>
                                                                        <p className="justified-text"> Renewable Energy Systems; Power Quality; Power Electronic Converters; Induction Motor Drives; Unipolar and Bipolar DC Microgrids, Digital Signal Processor Controlled Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-spradabane"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16240/16240.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-spradabane"
                                                                            target="_blank">Prof. Srinivasan Pradabane</a> </h5>
                                                                        <span> Assistant Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969289
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: spradabane@nitw.ac.in"> spradabane@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> 1. Multilevel inverters. 2. DC drives. 3. AC drives. 4. Electric propulsion. 5. Integration of renewables. 6. Driver circuits. 7. Intelligent control. 8. IoT applications. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-drsureshperli"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Suresh_Babu_Perli_2024-3-7-17-42-44.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-drsureshperli"
                                                                            target="_blank">Prof. Suresh Babu Perli</a> </h5>
                                                                        <span> Associate Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8977123345
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: drsureshperli@nitw.ac.in "> drsureshperli@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power System Protection, Electric Vehicles, Smart Grid Protection, Digital Filtering Algorithms Design. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-swatikjm"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Swati_Devabhaktuni_2024-3-7-17-44-6.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-swatikjm"
                                                                            target="_blank">Prof. Swati Devabhaktuni</a> </h5>
                                                                        <span> Assistant Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9618371888
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: swatikjm@nitw.ac.in  "> swatikjm@nitw.ac.in   </a>
                                                                        </p>
                                                                        <p className="justified-text"> Power Electronics, Induction motor drive, Linear induction motor and alternator. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-vinaykumar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Vinay_Kumar_T_2024-3-7-17-45-17.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-vinaykumar"
                                                                            target="_blank">Prof. Vinay Kumar T</a> </h5>
                                                                        <span> Assistant Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9885281148
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: vinaykumar@nitw.ac.in   "> vinaykumar@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text">  Power Electronics and Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ee-nvn"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Vishwanathan_N_2024-3-7-17-46-39.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ee-nvn"
                                                                            target="_blank">Prof. Vishwanathan N</a> </h5>
                                                                        <span> Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702468232
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: nvn@nitw.ac.in "> nvn@nitw.ac.in    </a>
                                                                        </p>
                                                                        <p className="justified-text">  Switched Mode Power Supplies, Induction Heating Applications and Electrical Drives. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>











                                                </div>

                                            </section>





                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/G._Veda_Prakash_2024-3-7-16-26-4.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">G. Veda Prakash</h6> <span>Assistant (SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462201/ 8332969540 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gvpnitw@nitw.ac.in">gvpnitw@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/A._Nagabhushanan_2024-3-7-16-27-37.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">A. Nagabhushanan</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462262/ 8332969348 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:nagabhushanam.98@nitw.ac.in">nagabhushanam.98@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Pooja_Kurdekar_2023-10-12-15-56-36.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Pooja Kurdekar</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462262/ 7204279549 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:poojak@nitw.ac.in">poojak@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Sabavath_Rahul_Naik_2024-3-7-16-29-2.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Sabavath Rahul Naik</h6>
                                                                <span>Senior Technician </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462264/ 9701317684 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:sabavathr@nitw.ac.in">sabavathr@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Bollam_Venu_2024-3-7-16-30-5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Bollam Venu</h6>
                                                                <span>Senior Technician </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9014042461 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bollamv@nitw.ac.in">bollamv@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Angali_Sai_Kumar_2024-3-7-16-36-29.jpg" alt="" className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Angali Sai Kumar</h6>
                                                                <span>Senior Technician </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462261/ 9603947427 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:angalis@nitw.ac.in">angalis@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>












                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/T._Veeranna_2024-3-7-16-37-46.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">T. Veeranna</h6> <span>Technician (SG-I)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462260/ 8332969303 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/D._Veeraiah_2024-3-7-16-38-57.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">D. Veeraiah</h6> <span>Technician (SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462261/ 8332969482 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/K._Rajender_2023-10-6-12-37-58.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K. Rajender</h6> <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969570 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/A._Srinivas_2024-3-7-16-41-25.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">A. Srinivas</h6> <span>Technician (UG)
                                                                </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462065/ 8332969278 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bottusin786@gmail.com">bottusin786@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/G._Murali_2023-10-6-12-38-49.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">G. Murali</h6>
                                                                <span>Technician (UG)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462201/ 8332969727 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:-">-</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="CHEDeptAssets/images/st5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Mohd. Afzal pasha</h6> <span>Caretaker SG-II</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info">
                                                                        <i className="fa fa-phone" aria-hidden="true"></i> 8332969648
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>








                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/K._Venkataiah_2023-10-12-16-8-21.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K. Venkataiah</h6>
                                                                <span>Senior care taker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462262/ 8179835314 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ashishzone$18@gmail">ashishzone$18@gmail</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mohd_Asifuddin_2024-3-7-16-43-47.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Mohd Asifuddin</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 6303974920 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mohammeda@nitw.ac.in">mohammeda@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src=" https://nitw.ac.in/api/static/files/Oddem_Praveen_2024-3-7-16-47-2.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Oddem Praveen</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2462263/9177799895 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:oddemp@nitw.ac.in">oddemp@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Banoth_Ravinder_2023-10-12-16-13-35.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Banoth Ravinder</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9490113065 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:banothr@nitw.ac.in">banothr@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}





                                            </div>

                                        </div>







                                        <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                            aria-labelledby="nav-AdjunctFaculty-tab">

                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">


                                                    {/* <div className="d-flex align-items-center">
                                                       
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Dr. Surajit Sannigrahi </h6>
                                                            <div className=" mt-2">
                                                                <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:surajit710@yahoo.in">surajit710@yahoo.in</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div> */}



                                                </div>
                                            </div>






                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">


                                                    <div className="d-flex align-items-center">
                                                        <div className="image"> <img src="https://nitw.ac.in/api/static/files/Akshay_Kumar_Rathore_2023-10-16-16-19-45.jpg" alt=""
                                                            className="img-fluid" width="150" height="190" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Akshay Kumar Rathore, IEEE Fellow</h6>
                                                            <span>Professor (Engineering) and Program Leader (Electrical Power Engineering)
                                                            </span>
                                                            <p className="justified-text">Singapore Institute of Technology, Singapore
                                                            </p>

                                                            <div className=" mt-0">
                                                                <span className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: akshaykumar.rathore@singaporetech.edu.sg"> akshaykumar.rathore@singaporetech.edu.sg</a>
                                                                </span><br></br>
                                                                <span className="justified-text"><b> For more details follow link: </b> <a href="https://www.singaporetech.edu.sg/directory/faculty/akshay-kumar-rathore">https://www.singaporetech.edu.sg/directory/faculty/akshay-kumar-rathore</a></span>


                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>




                                            <div className="col-md-12 profile-info-individual">
                                                <div className="row">


                                                    <div className="d-flex align-items-center">
                                                        <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof.N_D_R_Sarma_2023-10-16-16-21-15.jpg" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Sarma (NDR) Nuthalapati, PhD </h6>
                                                            <span>NDRS SEMS Consultancy, LLC,
                                                            </span>
                                                            <p className="justified-text">Adjunct Professor, Texas A&M University, College Station, TX.
                                                            </p>
                                                            <div className=" mt-0">
                                                                <span className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto: ndrs.sems.consultancy@gmail.com "> ndrsarma@ieee.org,    ndrs.sems.consultancy@gmail.com   </a>
                                                                </span><br></br>
                                                                <span className="justified-text"><b> For more details follow link: </b> <a href=" www.ndrsarma.com "> www.ndrsarma.com </a></span>

                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
























                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">
                                            {/*<h4 className="">Ph.D. Awardee</h4>
                     <p className="justified-text">
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






<div className="row phd-std-row">





                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd1.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Protection of Different Configured Transmission Lines & Frequency Control of Microgrid Using AI Techniques</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Gotte Vikram Raju</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9441680640
                                            <br /> <span> <a href="mailto:gottevr@student.nitw.ac.in" >gottevr@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd2.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Investigations on Control Flexibilities of Grid Integrated Solar Photovoltaic Energy Conversion System</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ramesh Daravath</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9014606012
                                            <br /> <span> <a href="mailto:rd719035@student.nitw.ac.in" >rd719035@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd3.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Renewable Energy Systems, Bipolar DC Microgrid, Energy management, Power Quality</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Dodda Satish Reddy</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9391209707
                                            <br /> <span> <a href="mailto:ds719036@student.nitw.ac.in" >ds719036@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd4.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Investigations on the Optimal Operation of Active Distribution System using Soft Computing Techniques</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ankeshwarapu Sunil</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9908389701
                                            <br /> <span> <a href="mailto:asunil037@student.nitw.ac.in" >asunil037@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd5.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>NONISOLATED DC-DC CONVERTERS</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">M F Baba</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9966807180
                                            <br /> <span> <a href="mailto:mfbaba@student.nitw.ac.in" >mfbaba@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd6.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Active AC/ DC LED Driver circuits for Lighting System</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kanithi Ashok Kumar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9490949340
                                            <br /> <span> <a href="mailto:ka719040@student.nitw.ac.in" >ka719040@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd7.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Control technics to power converters</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kalvapalli Srikanth Reddy</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9494535455
                                            <br /> <span> <a href="mailto:ks720033@student.nitw.ac.in" >ks720033@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd8.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Microgrid Control and cybersecurity</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">T Phani Swecha</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  8464096898
                                            <br /> <span> <a href="mailto:tp720035@student.nitw.ac.in" >tp720035@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd9.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>DC Microgrid Control </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">T Murali Krishna</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9908546423
                                            <br /> <span> <a href="mailto:tm721023@student.nitw.ac.in" >tm721023@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>





                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd10.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Global Power Optimization of PV Systems</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Rayappa David Amar Raj</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9441385577
                                            <br /> <span> <a href="mailto:rd721025@student.nitw.ac.in" >rd721025@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd11.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>High Gain Bidirectional DC-DC Converters for Renewable Energy Applications </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Dharavath Anusha</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9908329613
                                            <br /> <span> <a href="mailto:da721029@student.nitw.ac.in" >da721029@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd12.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Resonant Inverter application on Induction Heating </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ramesh Junju</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9177059397
                                            <br /> <span> <a href="mailto:rj721031@student.nitw.ac.in" >rj721031@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd13.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Five-phase PMSM drives</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Puli Rajanikanth</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  +917382034720
                                            <br /> <span> <a href="mailto:pr712044@student.nitw.ac.in" >pr712044@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd14.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power quality</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Koyyana Srinivasa Rao</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  8500900432
                                            <br /> <span> <a href="mailto:ks712053@student.nitw.ac.in" >ks712053@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd15.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Energy market Trading</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Choudhary Om Prakash </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  7079495629
                                            <br /> <span> <a href="mailto:cp712054@student.nitw.ac.in" >cp712054@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd16.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Electric Vehicle Battery Charging Systems</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Reddimalla Mounika</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  7093303153
                                            <br /> <span> <a href="mailto:rm21eerer03@student.nitw.ac.in" >rm21eerer03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            
                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd17.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Modelling of PV - Fuel Cells & Optimal Energy Management in a Micro Grid</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Syed Abdul Mujeer</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9494536446
                                            <br /> <span> <a href="mailto:sa21eerer04@student.nitw.ac.in" >sa21eerer04@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd18.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Development of silicone rubber Nano-micro composites used in transformer bushings</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Dhanunjaya Naidu Vangapandu</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  8341381931
                                            <br /> <span> <a href="mailto:dn21eerer06@student.nitw.ac.in" >dn21eerer06@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd19.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Predictive control techniques on Five Phase Induction Motor </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Naresh Rayavarapu</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  8328686524
                                            <br /> <span> <a href="mailto:nr21eerer08@student.nitw.ac.in" >nr21eerer08@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd20.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Predictive control techniques on Five Phase Induction Motor </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">G Ravi Kumar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9908661917
                                            <br /> <span> <a href="mailto:gr21eerer09@student.nitw.ac.in" >gr21eerer09@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd21.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>High gain DC DC converter with reconfiguration capability </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Velmajala Krishna </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9652513634
                                            <br /> <span> <a href="mailto:vk21eerer10@student.nitw.ac.in" >vk21eerer10@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd22.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Converters design for Induction cooking application</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kirlampalli Harija Rani</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9177681143
                                            <br /> <span> <a href="mailto:kh21eerer11@student.nitw.ac.in" >kh21eerer11@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd23.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Small signal stability analysis of networked microgrid </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Bhukya Mounika </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9515385363
                                            <br /> <span> <a href="mailto:bm21eerer12@student.nitw.ac.in" >bm21eerer12@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd24.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Design of High-Gain DC-DC converters for renewable energy applications </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">K Manikanta</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9014902012
                                            <br /> <span> <a href="mailto:km21eerer13@student.nitw.ac.in" >km21eerer13@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd25.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Investigation on False Data Injection attack against the Smart Electric Grid</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kamal Singh</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  8882872240
                                            <br /> <span> <a href="mailto:ks21eerer14@student.nitw.ac.in" >ks21eerer14@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd26.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Multilevel inverter with  PV application</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Merugu Arpitha</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  8187003858
                                            <br /> <span> <a href="mailto:ma21eerer15@student.nitw.ac.in" >ma21eerer15@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd27.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Energy market</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">T Pavani</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9704116006
                                            <br /> <span> <a href="mailto:tp21eerer16@student.nitw.ac.in" >tp21eerer16@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd28.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Artificial Intelligence Solutions for Effective Energy Management</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Vangipuram Srinivasa Raghavan</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9705736160
                                            <br /> <span> <a href="mailto:vs21eerer18@student.nitw.ac.in" >vs21eerer18@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd29.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Control techniques for DC-DC power converter with constant power loads </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Chandrasekhar Azad Narlapati </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  8681039123
                                            <br /> <span> <a href="mailto:nc21eerer19@student.nitw.ac.in" >nc21eerer19@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd30.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Inverter configurations for Induction heating Applications </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Mrs Fabia Akbar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9989350002
                                            <br /> <span> <a href="mailto:fa712056@student.nitw.ac.in" >fa712056@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd31.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Multi level inverters for  PV applications</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Palakurthi Ravali</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9676840015
                                            <br /> <span> <a href="mailto:pr22eer1r01@student.nitw.ac.in" >pr22eer1r01@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd32.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Microgrid stability and control</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kallepalli Sravan Kumar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9052593031
                                            <br /> <span> <a href="mailto:ks22eer1r02@student.nitw.ac.in" >ks22eer1r02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd33.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Renewable Integrated Transmission Line Protection</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Rahul Pujari</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9515696364
                                            <br /> <span> <a href="mailto:PR22EER1R04@STUDENT.NITW.AC.IN" >PR22EER1R04@STUDENT.NITW.AC.IN</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd34.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Short Term Load Forecasting using AI Techniques</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Anil Kumar Maddali </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9494644426
                                            <br /> <span> <a href="mailto:ak22eer1r07@student.nitw.ac.in" >ak22eer1r07@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd35.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>High-Gain DC-DC converters for Grid Integration</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Pottabathula Raviteja</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9676395020
                                            <br /> <span> <a href="mailto:pr22eer1r08@student.nitw.ac.in" >pr22eer1r08@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd36.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Transactive control in local energy market </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Boda Bixam</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info">  9553939755
                                            <br /> <span> <a href="mailto:bb22eer1r09@student.nitw.ac.in" >bb22eer1r09@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd37.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Security Analysis of Power Systems Using Data Driven Methods</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">P Sai Nandini </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 7095017454
                                            <br /> <span> <a href="mailto:ps22eer1r10@student.nitw.ac.in" >ps22eer1r10@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd38.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Investigation on Ac Drives</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kodithala Vani</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8790624143
                                            <br /> <span> <a href="mailto:kv22eer2r01@student.nitw.ac.in" >kv22eer2r01@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd39.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Demand Response in Smart grid</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ravi Kumar K</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9866987612
                                            <br /> <span> <a href="mailto:rk22eer2r02@student.nitw.ac.in" >rk22eer2r02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd40.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Hybrid Energy Storage systems</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Bomma Siddhartha</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9948725235
                                            <br /> <span> <a href="mailto:bs22eer2r03@student.nitw.ac.in" >bs22eer2r03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd41.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Ceramifiable Silicone Rubber Nano composites</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Jatoth Varun </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9553381077
                                            <br /> <span> <a href="mailto:jv22eer2r04@student.nitw.ac.in" >jv22eer2r04@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd42.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Robust control on industrial automation and mechatronic systems</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Sai Lakshmi S</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9042180801
                                            <br /> <span> <a href="mailto:sl22eer2r06@student.nitw.ac.in" >sl22eer2r06@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd43.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power electronic converters & control Techniques for EV applications</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Varipalli Krupakar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9000283951
                                            <br /> <span> <a href="mailto:vk23eer1r02@student.nitw.ac.in" >vk23eer1r02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power quality</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">K V Gayatri</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 7893877017
                                            <br /> <span> <a href="mailto:kv23eer1r03@student.nitw.ac.in" >kv23eer1r03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd45.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>High gain DC-DC converter</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Boda Jayasri</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9149811660
                                            <br /> <span> <a href="mailto:bj23eer1r05@student.nitw.ac.in" >bj23eer1r05@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd46.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Multi level inverters </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Y Naomi </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8985529557
                                            <br /> <span> <a href="mailto:yn23eer1r06@student.nitw.ac.in" >yn23eer1r06@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd47.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Ancillary power market</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Swapna Boddepalli</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9441161722
                                            <br /> <span> <a href="mailto:sb23eer1r07@student.nitw.ac.in" >sb23eer1r07@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd48.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Multiport converters for EV Applications</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">C Rajender</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8309487651
                                            <br /> <span> <a href="mailto:rc23eer1r08@student.nitw.ac.in" >rc23eer1r08@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd49.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Electric Vehicles</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Yezarla Narayana Rao</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 7799063791
                                            <br /> <span> <a href="mailto:yn23eer1r09@student.nitw.ac.in" >yn23eer1r09@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd50.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>LED Drivers</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Banoth Chandrasingh</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9398175423
                                            <br /> <span> <a href="mailto:bc23eer1r10@student.nitw.ac.in" >bc23eer1r10@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd51.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>MicroGrid Stability</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ogirla Rakesh Pal</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9492588892
                                            <br /> <span> <a href="mailto:23eer1r11@student.nitw.ac.in" >23eer1r11@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd52.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power Electronics application in renewable energy </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kothuru Sudhakar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9590058209
                                            <br /> <span> <a href="mailto:ks23eer2r02@student.nitw.ac.in" >ks23eer2r02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd53.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power system monitoring and control </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Rambabu Thatikayala </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8686872624
                                            <br /> <span> <a href="mailto:rt23eer2r03@student.nitw.ac.in" >rt23eer2r03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd54.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Large Integration of Renewable Energy Sources</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Syed Shahbazuddin</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8886663203
                                            <br /> <span> <a href="mailto:ss23eer2r04@student.nitw.ac.in" >ss23eer2r04@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd55.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power Electronics</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kolipaka Spandana</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9963705838
                                            <br /> <span> <a href="mailto:ks23eer2r05@student.nitw.ac.in" >ks23eer2r05@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd56.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Smart Grid protection </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Yedlapelly Manoj Bharadwaj</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9381973080
                                            <br /> <span> <a href="mailto:ym23eer2r06@student.nitw.ac.in" >ym23eer2r06@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd57.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Photovoltaic systems </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Vailla Rohith Chandra </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9704432175
                                            <br /> <span> <a href="mailto:vr23eer2r07@student.nitw.ac.in" >vr23eer2r07@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd58.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Grid Integration</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kalluri Nagachari </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 7702651114
                                            <br /> <span> <a href="mailto:kn23eer2r09@student.nitw.ac.in" >kn23eer2r09@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd59.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power system</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Shweta kadam</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 7414911609
                                            <br /> <span> <a href="mailto:ks23eer2r10@student.nitw.ac.in" >ks23eer2r10@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd60.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Renewable Energy</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Nenavath Saida Naik </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8099313344
                                            <br /> <span> <a href="mailto:ns23eer2r11@student.nitw.ac.in" >ns23eer2r11@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd61.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Networked microgrids</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Dhanavath Raja </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 7569176546
                                            <br /> <span> <a href="mailto:dr721028@student.nitw.ac.in" >dr721028@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd62.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Solar MPPT decrease oscillations and to mailtain voltage constant</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">B Avinash</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9493876541
                                            <br /> <span> <a href="mailto:ab22eer1r05@student.nitw.ac.in" >ab22eer1r05@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd63.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Fault tolerance on motors</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kattamuri V V D Ramana Kumar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9505128418
                                            <br /> <span> <a href="mailto:kv22eer1r03@student.nitw.ac.in" >kv22eer1r03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd64.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Investigating control strategies for Modular Multilevel Converters in grid-connected applications</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ambati Dinesh Kumar</h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 939029019
                                            <br /> <span> <a href="mailto:dinesha93@student.nitw.ac.in" >dinesha93@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd65.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Accurate Reactive power control of AC Microgrid</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Kanapala Rosaiah </h6>
                                            <span>Full-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8497999129
                                            <br /> <span> <a href="mailto:kr21eerer07@student.nitw.ac.in" >kr21eerer07@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd66.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Assessment of flexibility indices upon high penetration of renewables</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Srikanth Reddy Yanala</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9985154522
                                            <br /> <span> <a href="mailto:sr717119@student.nitw.ac.in" >sr717119@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd67.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Controllers for Speed Control of BLDC Motors</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Devasish Dey</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9441070560
                                            <br /> <span> <a href="mailto:devasish@student.nitw.ac.in">devasish@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd68.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power quality improvement using voltage source converters </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Gajangi Arun Kumar</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9885450321
                                            <br /> <span> <a href="mailto:ga720036@student.nitw.ac.in">ga720036@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd69.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Bidirectional DC DC Converters with battery and Super capacitor</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Vimala Devi V</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8712876411
                                            <br /> <span> <a href="mailto:vd721021@student.nitw.ac.in">vd721021@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd70.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Optimizing Dynamic Stability of Hybrid AC/DC Microgrids against External Perturbations: A TDO-CANN Hybrid Approach</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">M Paramesh </h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9949425754 
                                            <br /> <span> <a href="mailto:mp721024@student.nitw.ac.in">mp721024@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd71.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Solid nanodielectrics for outdoor harsh HV applications</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Moutusi Paul</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9000507758
                                            <br /> <span> <a href="mailto:mp721027@student.nitw.ac.in">mp721027@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd72.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Insulation Coordination of UHV AC Transmission Lines</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Sheri Abhishek Reddy</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9885813113
                                            <br /> <span> <a href="mailto:as712045@student.nitw.ac.in">as712045@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>





                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd73.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>SRM Drive</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Morri Prashanth</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9885270287
                                            <br /> <span> <a href="mailto:pm712048@student.nitw.ac.in">pm712048@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd74.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Wide Input Voltage Applications of DC-DC Converters</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">U Vijaya Laxmi</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9959048937
                                            <br /> <span> <a href="mailto:vu712049@student.nitw.ac.in">vu712049@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd75.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Wide Input Voltage Applications of DC-DC Converters</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Karthika Mangamuri</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9030622226
                                            <br /> <span> <a href="mailto:km712051@student.nitw.ac.in">km712051@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd76.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Investigations on Optimal PV Array Reconfiguration Techniques for Maximum Power Extraction</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Madavena Kumaraswamy</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9542690725
                                            <br /> <span> <a href="mailto:mk712052@student.nitw.ac.in">mk712052@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd77.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Induction motor drive for electric propulsion </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Praveen Kumar Pandugu </h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9849737335
                                            <br /> <span> <a href="mailto:pp21eerep02@student.nitw.ac.in">pp21eerep02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd78.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>AC Microgrid Load Frequency Control using different Controllers</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Sudipta Mitra</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 08013576404
                                            <br /> <span> <a href="mailto:sm21eerep04@student.nitw.ac.in">sm21eerep04@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd79.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Investigations on LMP Computation in Distribution System using Intelligent Techniques</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Pentam Sirisha</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9966338284
                                            <br /> <span> <a href="mailto:sp21eerep05@student.nitw.ac.in">sp21eerep05@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd80.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Single Stage High Performance Bidirectional inverter / rectifier</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">K Sudha</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8919624195
                                            <br /> <span> <a href="mailto:kk21eerep06@student.nitw.ac.in">kk21eerep06@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd81.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Microgrid</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Murali A</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8179448416
                                            <br /> <span> <a href="mailto:ma21eerep07@student.nitw.ac.in">ma21eerep07@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd82.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Microgrid</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Yellapureddy Vijay Kumar</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9700500258
                                            <br /> <span> <a href="mailto:yv21eerep08@student.nitw.ac.in">yv21eerep08@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd83.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Voltage stability in microgrids</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Punna Kalpana </h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9948107748
                                            <br /> <span> <a href="mailto:pk22eer1p05@student.nitw.ac.in">yv21eerep08@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd84.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Closed-loop control of MPC with a Quasi-Z-Source Inverter</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">P Vinodh Kumar</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9866940740
                                            <br /> <span> <a href="mailto:pv22eer2p03@student.nitw.ac.in">pv22eer2p03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd85.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Robust flight control, Guidance and Estimation for air to surface aerospace vehicle</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">R Laxman</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9490304723
                                            <br /> <span> <a href="mailto:rl22eer2p04@student.nitw.ac.in">rl22eer2p04@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd86.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Robust flight control, Guidance and Estimation for air to surface aerospace vehicle</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Gorla Anjali Devi </h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 7036065290
                                            <br /> <span> <a href="mailto:ga23eer1p02@student.nitw.ac.in">ga23eer1p02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd87.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Micro Grid</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">M Srinivas</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9640133930
                                            <br /> <span> <a href="mailto:ms23eer1p03@student.nitw.ac.in">ms23eer1p03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd88.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Induction Heating Applications </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">B Madhuri</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9912860858
                                            <br /> <span> <a href="mailto:bm23eer1p04@student.nitw.ac.in">bm23eer1p04@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd89.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Control of multi-phase machines</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ajith Katanguri</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9573327223
                                            <br /> <span> <a href="mailto:ak23eer2p01@student.nitw.ac.in">ak23eer2p01@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd90.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>I am in Course work, Yet to be finalised</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Gandhi Yarra</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9440418705,  8309085171
                                            <br /> <span> <a href="mailto:gy23eer2p02@student.nitw.ac.in">gy23eer2p02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd91.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Stability assessment and power sharing  in AC-DC micro grid </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">KV Sowmya</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9160482375
                                            <br /> <span> <a href="mailto:sk22eer1p03@student.nitw.ac.in">sk22eer1p03@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd92.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Multi-Load Induction Heating Applications </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Thoutreddy DeepthiReddy </h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9962210408
                                            <br /> <span> <a href="mailto:td21eerer02@student.nitw.ac.in">td21eerer02@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd93.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Renewable Energy Resources</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Paruchuri Rajendra Bhanu Teja</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8247893200
                                            <br /> <span> <a href="mailto:pr23eer1p07@student.nitw.ac.in">pr23eer1p07@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd94.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Design and Implementation of High Gain DC-DC Converter</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Vinay Kumar V</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9985284264
                                            <br /> <span> <a href="mailto:vk22eer1p04@student.nitw.ac.in">vk22eer1p04@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd95.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Renewable Energy Sources impact on Distributed System</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Naga Swetha Boppana </h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9505758279
                                            <br /> <span> <a href="mailto:ns23eer1p06 @student.nitw.ac.in">ns23eer1p06 @student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd96.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Backlash compensation for EMA system</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Ramesh Kumar</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9440750145
                                            <br /> <span> <a href="mailto:rk22eer2p01@student.nitw.ac.in">rk22eer2p01@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd97.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power Management Strategies of a Grid Integrated Hybrid Distributed Generation Systems Using Intelligent Control</b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">T Praveen kumar</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9989734599
                                            <br /> <span> <a href="mailto:tp719125@student.nitw.ac.in">rk22eer2p01@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>




                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd98.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Power system operation and control </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Rajulapati Sudha</h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 9849394228
                                            <br /> <span> <a href="mailto:rs23eer1p05@student.nitw.ac.in">rs23eer1p05@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>



                                            <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                            <div className="phd-std-member">
                                            <div className="phd-std-img">
                                            <img src="EEEDeptAssets/images/phd99.jpg" className="img-fluid image" alt="" />
                                            <div className="overlay">
                                            <div className="std-details ">
                                             <p>Research Area : <b>Dual active bridge converter for EV charging </b></p>
                                            </div>
                                            </div>
                                            </div>
                                            <h6 className="std-title">Iragattapu Neelima </h6>
                                            <span>Part-Time Research Scholar    </span>
                                            <hr />
                                            <p className="contact-info"> 8317524780
                                            <br /> <span> <a href="mailto:in721018@student.nitw.ac.in">in721018@student.nitw.ac.in</a> </span></p>
                                            </div>
                                            </div>


                                            




                                            



                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">
                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                target="_blank"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                    target="_blank">
                                                    <h6>Prof. R. Padmavathy</h6> <span> Faculty Advisor </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9490165347 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:cse_hod@nitw.ac.in"
                                                            style={{ color: "#808080" }}>cse_hod@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Prof. U. S. N. Raju</h6> <span> Treasurer </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    <div className="row notable-alumni">




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/V.A._Sastry_2023-11-2-10-35-50.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>V.A. Sastry</h6>
                                                    <p className="justified-text"><b>Batch: </b>1964<br />
                                                        <b>Present Position: </b>Entrepreneur
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/P._Sudhakar_2023-11-2-18-26-23.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>P. Sudhakar</h6>
                                                    <p className="justified-text"><b>Batch: </b>1964<br />
                                                        <b>Present Position: </b>CMD,ECIL
                                                    </p>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Rao_Remala_2023-11-2-18-27-16.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Rao Remala</h6>
                                                    <p className="justified-text"><b>Batch: </b>1970<br />
                                                        <b>Present Position: </b>First non American employee of MICROSOFT
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/RP_Singh_2023-11-2-18-27-50.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>R P Singh</h6>
                                                    <p className="justified-text"><b>Batch: </b>1979 <br />
                                                        <b>Present Position: </b>CMD, SJVN Ltd
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Satya_Bolli_2023-11-2-18-28-44.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Satya Bolli</h6>
                                                    <p className="justified-text"><b>Batch: </b>1978 <br />
                                                        <b>Present Position: </b>Entrepreneur
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/N._Venu_2023-11-2-18-29-16.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>N. Venu</h6>
                                                    <p className="justified-text"><b>Batch: </b>1988 <br />
                                                        <b>Present Position: </b>CMD, Hitachi Energy
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/R_S_T_Sai_2023-11-2-18-29-59.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>R S T Sai</h6>
                                                    <p className="justified-text"><b>Batch: </b>1977 <br />
                                                        <b>Present Position: </b>CMD, NHPC
                                                    </p>


                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/K._Linga_Reddy_2023-11-2-18-30-44.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>K. Linga Reddy</h6>
                                                    <p className="justified-text"><b>Batch: </b>1970 <br />
                                                        <b>Present Position: </b>CEO, PETE Transformers
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Praveen_Ghanta_2023-11-2-18-31-21.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Praveen Ghanta</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Synopsys Inc
                                                    </p>


                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Biju_Philipose_2023-11-2-18-31-51.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Biju Philipose</h6>
                                                    <p className="justified-text"><b>Batch: </b>1994 <br />
                                                        <b>Present Position: </b>Managing Director at Sevana Packaging Systems Pvt Ltd
                                                    </p>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof_K_Shanti_Swarup_2023-11-2-18-32-56.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. K Shanti Swarup</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professor in Electrical Engineering, IIT Chennai
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._Lalit_Goal_2023-11-2-18-33-25.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. Lalit Goal</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Director Renaissance Engineering Programme (REP) at Nanyang Technological University Singapore
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._T_V_Prabhakar_2023-11-2-18-34-8.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. T V Prabhakar</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professors in CSE, IIT Kanpur
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._N_D_R_Sarma_2023-11-2-18-34-40.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. N D R Sarma</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Adjunt Faculty, Electrical & Computer Engineering Texas A&M University
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._M_Veerachary_2023-11-2-18-35-15.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. M Veerachary</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professor in Electrical Engineering, IIT Delhi
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Prof._Srinivas_2023-11-2-18-35-43.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Prof. Srinivas</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Professor in Electrical Engineering, IIT Chennai
                                                    </p>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Ganesh_Sahay_2023-11-2-18-36-51.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Ganesh Sahay</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Divisional Railway Manager, Chennai at INDIAN RAILWAYS
                                                    </p>


                                                </div>
                                            </div>
                                        </div>









                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Mutyala_Raju_Revu_2023-11-2-18-37-34.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Mutyala Raju Revu</h6>
                                                    <p className="justified-text"><b>Batch: </b>- <br />
                                                        <b>Present Position: </b>Stood first in IAS in 2008
                                                    </p>


                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Amarjot_Singh_2023-11-2-18-38-2.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Amarjot Singh</h6>
                                                    <p className="justified-text"><b>Batch: </b>2011  <br />
                                                        <b>Present Position: </b>Founder & CEO at SkyLark Labs
                                                    </p>


                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/P._Satish_Reddy_2023-11-2-18-38-46.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>P. Satish Reddy</h6>
                                                    <p className="justified-text"><b>Batch: </b>2012 <br />
                                                        <b>Present Position: </b>AIR 97, IAS-UPSC-2014
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Satish_Kumar_2023-11-2-18-41-0.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Satish Kumar</h6>
                                                    <p className="justified-text"><b>Batch: </b> <br />
                                                        <b>Present Position: </b>AIR 58, IES, Assistant Director, Ministry of Power, GoI
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Kavali_Meghana_2023-11-2-18-41-33.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Kavali Meghana</h6>
                                                    <p className="justified-text"><b>Batch: </b> 2016<br />
                                                        <b>Present Position: </b>AIR 83, IAS-UOSC 2020
                                                    </p>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Shrikrishan_Yadav_2023-11-2-18-44-24.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Shrikrishan Yadav</h6>
                                                    <p className="justified-text"><b>Batch: </b> 2018<br />
                                                        <b>Present Position: </b>Assistant Director in Military Engineer Services(MES) ,IDSE Cadre
                                                    </p>


                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="https://nitw.ac.in/api/static/files/Bhoopendra_Singh_Meena_2023-11-2-18-46-3.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                <div className="data">
                                                    <h6>Bhoopendra Singh Meena</h6>
                                                    <p className="justified-text"><b>Batch: </b> 2018<br />
                                                        <b>Present Position: </b>Assistant Director in Military Engineer Services(MES) ,IDSE Cadre
                                                    </p>


                                                </div>
                                            </div>
                                        </div>





                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Dr. Pushmeet Kohli</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Principal Scientist and Team Lead, DeepMind,
                                                        London, UK
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Pavithra Prabhakar</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Professor, Kansas State University, Manhattan,
                                                        United States
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/vrajanna.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>V Rajanna</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> GM & VP, TCS- Hyderabad


                                                    </p>

                                           
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/aarthi.jpg  " alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Aarthi Subramanyan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Group Chief Digital Officer, Tata Sons Private
                                                        Limited, Mumbai
                                                    </p>

                                              
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/murali.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Murali Annavaram</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Professor at University of Southern California

                                                    </p>

                                                
                                                </div>
                                            </div>
                                        </div> */}




                                        {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Sridhar Muppala</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO, Silicon Valley

                                                    </p>

                                               
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Movva Ramu</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Microsoft Research Centre, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>K V Sridhar</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO Analytics CA, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Jitendra Lakotia</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Former VP of Cognos. USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Amarnadh Reddy</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CTO, CGG, Hyderabad
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}


                                        {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Feroz khan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Dy. Director, SAP Labs

                                                    </p>

                                                </div>
                                            </div>
                                        </div> */}


                                    </div>
                                </div>

                                {/* <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Big Data Analytics, Artificial Intelligence / Machine Learning, Data Science
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/5.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. RBV Subramanyam</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9491346969 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rbvs66@nitw.ac.in">rbvs66@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/6.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Radhakrishna</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9704988816 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:prkrishna@nitw.ac.in">prkrishna@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/11.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. T Ramakrishnudu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9866876842 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:trk@nitw.ac.in">trk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/14.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.V.Kadambari</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9705460461<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kvkadambari@nitw.ac.in">kvkadambari@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/22.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. M Srinivas</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8897064421<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msv@nitw.ac.in">msv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/27.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Venkateswara Rao Kagita</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 6281746931 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:venkat.kagita@nitw.ac.in">venkat.kagita@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Security</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R Padmavathy</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9381265691 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rpadma@nitw.ac.in">rpadma@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/21.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.M.Sandhya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9394468554<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:msandhya@nitw.ac.in">msandhya@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/23.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Kaveti Ilaiah</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9848916272 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ilaiahkavati@nitw.ac.in">ilaiahkavati@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Networks</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Software Engineering</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S Ravichandra</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969414 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ravic@nitw.ac.in">ravic@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/16.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Priyanka Chawla</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7982750652 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:priyankac@nitw.ac.in">priyankac@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/18.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof.Manjubala Bisi</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9502940360 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">manjubalabisi@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/29.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sangharatna Godboley</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7013306805<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:cse_hod@nitw.ac.in">sanghu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Cloud & Distributed Computing, IOT</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/8.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ch Sudhakar</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440647945 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:chapram@nitw.ac.in">chapram@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R.R.Rout</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969418<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rashrr@nitw.ac.in">rashrr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sriram Kailasam</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8894275490 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sriramk@nitw.ac.in">sriramk@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/25.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sanjaya Kumar Panda</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9861126947<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sanjay@nitw.ac.in">sanjay@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. E Suresh Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440959713 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:esbabu@nitw.ac.in">esbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K.BalaPrakashRao</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 7002457102 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bsprao@nitw.ac.in">bsprao@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Algorithms, BioInformatics, Computer Vision, Image Processing, Model-driven
                                                    Frameworks, Soft Computing</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K Ramesh</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kramesh@nitw.ac.in">kramesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/12.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raju Bhukya</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9700553922 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raju@nitw.ac.in">raju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/15.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P V Subba Reddy</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8790590436<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsr@nitw.ac.in">pvsr@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/13.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. U S N Raju</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9440149146 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:usnraju@nitw.ac.in">usnraju@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/19.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ijjina Earnest Paul</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9494466490<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:iep@nitw.ac.in">iep@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/24.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Sujit Das</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8536009758<br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sujit.das@nitw.ac.in">sujit.das@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="CSEDeptAssets/images/28.jpg" alt=""
                                                        className="img-fluid" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ramalingaswamy Cherukku</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9773827143 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rmlswamy@nitw.ac.in">rmlswamy@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        {/* <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span> */}
                                    </div>


                                    {/* <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 

                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}





                                </div>






                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div >
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default EEEDeptPage;