import { useState } from "react";
import '../css/cseDeptStyle.css';
const MathsDeptPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            {/* <img src="https://nitw.ac.in/api/static/files/mathematics_banner_2023-10-10-11-1-24.jpg" className="img-fluid" /> */}
            <img src="https://nitw.ac.in/api/static/files/mathsdept_2024-11-29-17-51-49.jpg" className="img-fluid" />
          </header>
          <div className="row">
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>About</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programmes</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                  <span>People </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected="false">
                  <span>Notable Alumni </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected="false">
                  <span>Students Society </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                    Group</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected="false"> <span>Awards and Honours</span> </a>
                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected="false">
                  <span>Laboratory & Facilities</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected="false"> <span>Photo Gallery</span> </a>

              </div>
              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ma-abenerji"
                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16397/16397.jpg" alt="" className="img-fluid" width="190" height="220" />
                <h6>Prof. A. Benerji Babu</h6>
              </a>
                <p className="justified-text"> Head of the Department <br /> <a href="mailto:maths_hod@nitw.ac.in"
                  style={{ color: "#808080" }}>maths_hod@nitw.ac.in</a> <br /> 9490165350 </p>
              </div>
            </div>
            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p className="justified-text">The Department of Mathematics was established in 1959 and has always shared the vision of the institute in striving for excellence in teaching and research activities.  Over the years, the department has evolved as one that provides excellent teaching and research in Applied Mathematical Sciences. </p>




                  </div>
                                      {/* NUMBERING START*/}
                                      <div class="row nirf-ranking-counter text-center">
                         
                         
                        
                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>22</span></h2>
                                 
                                 <h6 class="mt-0"><b>Faculty</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>4</span></h2>
                                 <h6 class="mt-0"><b>Staff</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>24</span></h2>
                                 <h6 class="mt-0"><b>PhD Research Scholars</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>211</span></h2>
                                 <h6 class="mt-0"><b>Undergraduate Students</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>117</span></h2>
                                 <h6 class="mt-0"><b>Postgraduate Students</b></h6>
                             </div>
                         </div>



                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>576</span></h2>
                                 <h6 class="mt-0"><b>Publications</b></h6>
                             </div>
                         </div>

                       
                     </div>

                  <div className="row vision-mission-section">
                    <div className="col-md-6 vision-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                        alt="" /> Vision</h4>
                      <p className="  mb-4">To be among the best mathematics departments in the country, to build an international reputation as a center of excellence in mathematics and computational research, training, and education, and to inculcate mathematical thinking in order to meet the challenges and growth of science and technology, as well as the needs of industry and society, with moral and ethical responsibility.</p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                        className="img-fluid" />
                    </div>
                    <div className="col-md-6 mission-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                        alt="" /> Mission</h4>
                      <p className="  mb-2">To attract motivated and talented students by providing a learning environment where they can learn and develop the mathematical and computational skills needed to formulate and solve real-world problems.</p>
                      <p className="  mb-2">To foster an environment conducive to quality research and to train principled and highly skilled researchers with clear thinking and determination capable of meeting the dynamic challenges of science and engineering.</p>
                      <p className="  mb-2">To keep up with the rapid advancements of technology while improving academic standards through innovative teaching and learning processes.</p>
                      <p className="  mb-2">To satisfy the country's human resource and scientific manpower requirements in mathematics through learner-centered contemporary education and research.</p>

                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className="mt-2 mb-2 notice-board1">
                      <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardMath/Mathematics" target="_blank"> Notices/Announcements</a>
                    </h4>
                  </div>

                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                  {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                  <div className="row">


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>B.Tech. (Mathematics and Computing)</h3>
                            <p className="justified-text">


                            </p>


                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="https://nitw.ac.in/api/static/files/B.Tech_Mathematics_and_Computing-Curriculum-new-2024-25_2024-7-19-15-45-56.pdf" target="_blank"> B.Tech (Mathematics and Computing) Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>

                        </div>
                      </div>
                    </div>


                    {/* <div className="col-md-6 col-sm-12"> */}
                      {/* <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx"> */}
                            {/* <h3>Postgraduate Program</h3>
                                                        <p className="justified-text">The Department of Chemical Engineering offers two Postgraduate Programs – M. Tech. in Chemical Engineering and M.Tech. in Systems and Control Engineering. From 2021-2022 academic year, the department has started a PG Diploma course in Systems and Control Engineering .</p> */}
                            {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                            {/* <h3> M.Tech., M.C.A, M.B.A </h3> */}
                            {/* <p className="justified-text">
                                                         Welcome to our new and distinguished multidisciplinary M.Tech. program in 'Sustainable Energy Generation and Storage Technologies (SEG&ST)’ launched in 2023-24. This interdisciplinary program is open to individuals from diverse backgrounds, including Chemical Engineering, Energy Engineering, Petrochemical Engineering, Electrical Engineering, Mechanical Engineering, Metallurgical and Materials Engineering, Civil Engineering, Biotechnology, and allied disciplines. The curriculum offers a range of exciting electives for a personalized learning experience. Join us to pioneer change and shape a greener, more sustainable future in the field of energy innovation.
                                                         </p> */}

                          {/* </div> */}
                          {/* / */}

                          {/* <p className="more"> <a className=" more-info-link"
                                                        href="CSEDeptAssets/docs/re-reivsed-m-tech-cse-syllabus-20-8-21v10.pdf"
                                                        target="_blank"> M.Tech CSE Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
{/* 
                        </div>
                      </div>
                    </div> */}


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Open Electives for all UG, PG and Ph.D. programs.</h3>
                            {/* <p className="justified-text"> M.Tech in Chemical Engineering (ChE) program offers a transformative learning experience through advanced research, specialized coursework, and practical applications in the complex realms of chemical engineering. Originally launched in 1968 as M.Tech. in Chemical Plant Engineering, it evolved in 1987 to focus on Computer Aided Process & Equipment Design (CAPED), and in 2015, it became M.Tech. in Chemical Engineering to meet industry demands. Admission is through CCMT for GATE-qualified students, with a few self-finance seats available.</p> */}

                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="-"
                            target="_blank"> Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Two year M.Sc Applied Mathematics</h3>
                            {/* <p className="justified-text">
                                                         M.Tech. in Systems and Control Engineering (SCE) is designed to equip engineers with specialized skills in process control and systems engineering. Originally launched as M.Tech. in Process Control in 2016, it evolved into M.Tech. in Systems and Control Engineering in 2021 to meet industry needs. This is an interdisciplinary program admitting students with different backgrounds such as Instrumentation & Control Engineering, Electrical Engineering, Electronics and Instrumentation, Chemical Engineering, etc. It integrates education and research to stay updated in the field of Control and Automation. Join us to master systems and control engineering and shape the future of industries.

                                                         </p> */}

                          </div>
                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/1_M.Sc_Applied_Mathematics-Curriculum-new-2024-25_2024-7-19-16-15-4.pdf"
                            target="_blank"> Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>






                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Two year M.Sc Mathematics and Scientific Computing</h3>
                            {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/2_M.Sc_Mathematics_and_Scientific_Computing-Curriculum-new-2024-25_2024-7-19-16-15-59.pdf"
                            target="_blank">Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Five year Integrated M.Sc. Mathematics</h3>
                            {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Int._M.Sc_(Mathematics)-Curriculum-new-2024-25_2024-7-19-16-6-35.pdf"
                            target="_blank">Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Minor in Applied and Computational Mathematics</h3>
                            {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Applied_and_Computational_Mathematics_2022-9-28-15-40-30.pdf"
                            target="_blank">Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Ph.D</h3>
                            <p className="justified-text">Ph.D. program on both Regular and Part-Time basis, and also under Quality Improvement Program (QIP). The Department is the only QIP centre for Mathematics in India.</p>

                            {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          {/* <p className="more"> <a className="buttton more-info-link"
                                                        href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                        target="_blank"> Rules and Regulations of Ph.D. Program <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
                        </div>
                      </div>
                    </div>

                  </div>

                </div>




                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div className="row laboratory-facilities">




                    <div className="details">

                      <h3>Computation Laboratory</h3>
                      <p className="justified-text">The Department has a full-fledged computational laboratory to meet the requirements of the M.Sc. students, research scholars and the faculty. </p>

                      <p className="justified-text">

                        Presently there are 24 systems in the Computation lab with advanced configuration.
                      </p>
                      <p className="justified-text">

                        The students are trained in computer programming languages like C, C++, FORTRAN and SQL. They also gain exposure in handling problems through mathematical software like MATLAB, Mathematica, Tecplot 360 etc.
                      </p>

                    </div>
                    <img src="https://oldweb.nitw.ac.in/media/uploads/2018/09/13/img_8568-copy.jpg" alt="" className="img-fluid mb-5" />






                  </div>
                </div>






                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                      id="nav-AdjunctFaculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor </a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                      Staff</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                      data-toggle="tab" role="tab"
                      aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars/ Postdoctoral Researchers</a>


                  </div>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">
                      {/*<h4 className=" mb-4">Faculty</h4>*/}
                      {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                      {/* <section id="profile-info">

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16397"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16397/16397.jpg" alt=""
                                                                className="img-fluid" width="150" height="180"/> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16397"
                                                                        target="_blank">Prof. Benerji Babu A</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 08702468831, 08332969455 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:abenerji@nitw.ac.in ">abenerji@nitw.ac.in </a> </p>
                                                                     <p className="justified-text"> Dynamo theory, Fluid Dynamics (Geophysical Fluid Dynamics), MHD Flows, Mathematical Modelling, and Instabilities.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16434"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16434/16434.jpg" alt=""
                                                                className="img-fluid" width="150" height="180"/> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16434"
                                                                        target="_blank">Prof. Ch. Ramreddy</a>
                                                                    </h5> <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9491824301 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:chramreddy@nitw.ac.in ">chramreddy@nitw.ac.in </a>
                                                                    </p>
                                                                     <p className="justified-text">Fluid Dynamics, Convective Transport, Flow through Porous Media, Stability of Boundary Layer Flows, Computational Methods (Keller-box Method, Shooting Method, Spectral Methods, Adomian Decomposition Method, Homotopy Analysis Method, Finite Element Methods).</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16398"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="https://wsdc.nitw.ac.in/facultynew/media/16398/16398.jpg" alt="" className="img-fluid" width="150" height="180"/> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16398"
                                                                        target="_blank">Prof. D. Bhargavi</a> </h5>
                                                                    <span> Associate Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969456 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kola@nitw.ac.in">BHARGAVI@nitw.ac.in</a>
                                                                    </p>
                                                                     <p className="justified-text">Fluid Dynamics; Numerical Heat Transfer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ma-dsc"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16393/16393.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-dsc"
                                                                            target="_blank">Prof. D. Srinivasacharya</a> </h5>
                                                                        <span> Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849187249 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:dsc@nitw.ac.in"> dsc@nitw.ac.in  </a>
                                                                        </p>
                                                                         <p className="justified-text">Fluid Mechanics; Computational Fluid Dynamics; Hydrodynamic Stability, Micropolar and Couple Stress Fluids; Convective Heat and Mass Transfer; Biomechanics; Flow through Porous Media, Nanofluid Flows, ANN in Fluid Mechanics</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16392"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16392/16392.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16392"
                                                                            target="_blank">Prof. Debashis Dutta</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462820 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ddutta@nitw.ac.in"> ddutta@nitw.ac.in </a> </p>
                                                                         <p className="justified-text">Operations Research, Statistics, Soft Computing, Mathematical Modeling.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ma-ndeepika"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17057/17057.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-ndeepika"
                                                                            target="_blank">Prof. Deepika Neela</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9703134134 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ndeepika@nitw.ac.in"> ndeepika@nitw.ac.in  </a>
                                                                        </p>
                                                                         <p className="justified-text">Hydrodynamic Stability; Convection in Porous media.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16396"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16396/16396.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16396"
                                                                            target="_blank">Prof. Hari Ponnamma Rani</a> </h5>
                                                                        <span> Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9908143247 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:hprani@nitw.ac.in"> hprani@nitw.ac.in  </a>
                                                                            </p>
                                                                         <p className="justified-text"> Nonlinear dynamics, Finite difference methods, Finite volume methods, Computational Fluid Dynamics, Instability and vortex analysis, Heat and Mass Transfer problems.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17007"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17007/17007.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17007"
                                                                            target="_blank">Prof. Jagannath Roy</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9064013428  <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:jroy@nitw.ac.in"> jroy@nitw.ac.in </a>
                                                                        </p>
                                                                         <p className="justified-text">Operations Research, Fuzzy Set Theory, Decision Theory, Transportation & Logistics Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17004"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17004/17004.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17004"
                                                                            target="_blank">Prof. K. Kaladhar</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9491596219 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kaladhar@nitw.ac.in"> kaladhar@nitw.ac.in  </a>
                                                                        </p>
                                                                         <p className="justified-text"> Fluid Dynamics, Convective Heat and Mass Transfer, Flow through Porous Media, Analytical and Semi-analytical Methods, Bio- Mathematics</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ma-kasi"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16389/16389.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-kasi"
                                                                            target="_blank">Prof. Kasi Viswanadham K N S</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9866028867, 8332969448 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:kasi@nitw.ac.in"> kasi@nitw.ac.in  </a>
                                                                        </p>
                                                                         <p className="justified-text">Numerical Analysis, Finite Element Methods,Optimization Techniques</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16401"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16401/16401.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16401"
                                                                            target="_blank">Prof. P. Muthu</a> </h5>
                                                                        <span> Associate Professor </span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702462833, 8332969452 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:muthuatbits@gmail.com ">muthuatbits@gmail.com 
                                                                            </a> </p>
                                                                         <p className="justified-text">Computational Fluid Dynamics (CFD) ; Bio Mechanics ; Mathematical Modelling ; Hydrodynamic Lubrication ; Mathematical Biology.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16394"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16394/16394.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16394"
                                                                            target="_blank">Prof. Pranitha J</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9885146885 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:pranitha@nitw.ac.in ">pranitha@nitw.ac.in </a>
                                                                        </p>
                                                                         <p className="justified-text"> Fluid Dynamics; Convective Heat transfer; Flow through Porous Media; Nanofluids.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16395"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16395/16395.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16395"
                                                                            target="_blank">Prof. R. S. Selvaraj</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9908539330, 8332969454 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:rsselva@nitw.ac.in"> rsselva@nitw.ac.in   </a>
                                                                        </p>
                                                                         <p className="justified-text">Algebraic Coding Theory; --- (involving Abstract Algebra, Linear Algebra, Combinatorics, finite normed/metric spaces)</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16391"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16391/16391.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16391"
                                                                            target="_blank">Prof. Ramana Murthy J V</a> </h5>
                                                                        <span>Professor (HAG)</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969449 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:jvr@nitw.ac.in "> jvr@nitw.ac.in  </a> </p>
                                                                         <p className="justified-text">Fluid Mechanics; Homotopy Analysis Method(HAM); Perturbation Methods.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                  

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16942"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16942/16942.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16942"
                                                                            target="_blank">Prof. Satyanarayana Engu</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9611422816
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:satya@nitw.ac.in"> satya@nitw.ac.in  </a>
                                                                        </p>
                                                                         <p className="justified-text">Nonlinear Partial differential equations </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ma-ysr"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16898/16898.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-ysr"
                                                                            target="_blank">Prof. Sreenivasa Rao Y</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9494776866
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:ysr@nitw.ac.in"> ysr@nitw.ac.in </a> </p>
                                                                         <p className="justified-text"> Cryptography, Cloud Security, Blockchain Security.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16698"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16698/16698.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16698"
                                                                            target="_blank">Prof. Srinivas Jangili</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849835490 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:jsrinivas@nitw.ac.in"> jsrinivas@nitw.ac.in </a>
                                                                        </p>
                                                                         <p className="justified-text">Microfluidics; Nanofluidics; Electrokinetic Transport Phenomena; Multi-Layer Flows; Heat Transfer Analysis; Analytical and Semi-analytical Methods;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17006/17006.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180"/> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla"
                                                                            target="_blank">Prof. Triveni Prasad Shukla</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9702961319 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:tpshukla@nitw.ac.in "> tpshukla@nitw.ac.in  </a>
                                                                        </p>
                                                                         <p className="justified-text">Quasilinear Partial Differential Equations; Nonlinear Waves; Classical and Nonclassical Shock Waves; Dispersive Shock Waves; Undercompressive Shock Waves</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                   
                                                  
                                            </section> */}
                      <section id="profile-info">

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-abenerji"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16397/16397.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-abenerji"
                                    target="_blank">Prof. A. Benerji Babu</a> </h5>
                                  <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702468831, 08332969455 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:abenerji@nitw.ac.in ">abenerji@nitw.ac.in </a> </p>
                                  <p className="justified-text"> Dynamo theory, Fluid Dynamics (Geophysical Fluid Dynamics), MHD Flows, Mathematical Modelling, and Instabilities.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-chramreddy"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16434/16434.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-chramreddy"
                                    target="_blank">Prof. Ch. Ramreddy</a>
                                  </h5> <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9491824301 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chramreddy@nitw.ac.in ">chramreddy@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">Fluid Dynamics, Convective Transport, Flow through Porous Media, Stability of Boundary Layer Flows, Computational Methods (Keller-box Method, Shooting Method, Spectral Methods, Adomian Decomposition Method, Homotopy Analysis Method, Finite Element Methods).</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-bhargavi"
                              target="_blank" className="img-fluid"> <img
                                src="https://wsdc.nitw.ac.in/facultynew/media/16398/16398.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-bhargavi"
                                    target="_blank">Prof. D. Bhargavi</a> </h5>
                                  <span> Associate Professor </span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969456 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kola@nitw.ac.in">BHARGAVI@nitw.ac.in</a>
                                  </p>
                                  <p className="justified-text">Fluid Dynamics; Numerical Heat Transfer</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-dsc"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16393/16393.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-dsc"
                                    target="_blank">Prof. D. Srinivasacharya</a> </h5>
                                  <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9849187249 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:dsc@nitw.ac.in"> dsc@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Fluid Mechanics; Computational Fluid Dynamics; Hydrodynamic Stability, Micropolar and Couple Stress Fluids; Convective Heat and Mass Transfer; Biomechanics; Flow through Porous Media, Nanofluid Flows, ANN in Fluid Mechanics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-ddutta"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16392/16392.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-ddutta"
                                    target="_blank">Prof. Debashis Dutta</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462820 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ddutta@nitw.ac.in"> ddutta@nitw.ac.in </a> </p>
                                  <p className="justified-text">Operations Research, Statistics, Soft Computing, Mathematical Modeling.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-ndeepika"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17057/17057.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-ndeepika"
                                    target="_blank">Prof. Deepika Neela</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9703134134 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ndeepika@nitw.ac.in"> ndeepika@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Hydrodynamic Stability; Convection in Porous media.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-hprani"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16396/16396.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-hprani"
                                    target="_blank">Prof. Hari Ponnamma Rani</a> </h5>
                                  <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9908143247 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:hprani@nitw.ac.in"> hprani@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Nonlinear dynamics, Finite difference methods, Finite volume methods, Computational Fluid Dynamics, Instability and vortex analysis, Heat and Mass Transfer problems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-jroy"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17007/17007.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-jroy"
                                    target="_blank">Prof. Jagannath Roy</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9064013428  <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jroy@nitw.ac.in"> jroy@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">Operations Research, Fuzzy Set Theory, Decision Theory, Transportation & Logistics Management</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-kaladhar"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17004/17004.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-kaladhar"
                                    target="_blank">Prof. K. Kaladhar</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9491596219 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kaladhar@nitw.ac.in"> kaladhar@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Fluid Dynamics, Convective Heat and Mass Transfer, Flow through Porous Media, Analytical and Semi-analytical Methods, Bio- Mathematics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-kasi"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16389/16389.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-kasi"
                                    target="_blank">Prof. Kasi Viswanadham K N S</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9866028867, 8332969448 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kasi@nitw.ac.in"> kasi@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Numerical Analysis, Finite Element Methods,Optimization Techniques</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-pm"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16401/16401.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-pm"
                                    target="_blank">Prof. P. Muthu</a> </h5>
                                  <span> Professor </span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702462833, 8332969452 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pm@nitw.ac.in ">pm@nitw.ac.in
                                    </a> </p>
                                  <p className="justified-text">Computational Fluid Dynamics (CFD) ; Bio Mechanics ; Mathematical Modelling ; Hydrodynamic Lubrication ; Mathematical Biology.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-pranitha"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16394/16394.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-pranitha"
                                    target="_blank">Prof. Pranitha J</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9885146885 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pranitha@nitw.ac.in ">pranitha@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text"> Fluid Dynamics; Convective Heat transfer; Flow through Porous Media; Nanofluids.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-rajibs"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/rajibs_2024-12-6-19-9-6.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-rajibs"
                                    target="_blank">Prof. Rajib Sarkar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9884298990 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rajibs@nitw.ac.in ">rajibs@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text"> Combinatorial Commutative Algebra</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-rsselva"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16395/16395.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-rsselva"
                                    target="_blank">Prof. R. S. Selvaraj</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9908539330, 8332969454 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rsselva@nitw.ac.in"> rsselva@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text">Algebraic Coding Theory; --- (involving Abstract Algebra, Linear Algebra, Combinatorics, finite normed/metric spaces)</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-jvr"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16391/16391.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-jvr"
                                    target="_blank">Prof. Ramana Murthy J V</a> </h5>
                                  <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969449 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jvr@nitw.ac.in "> jvr@nitw.ac.in  </a> </p>
                                  <p className="justified-text">Fluid Mechanics; Homotopy Analysis Method(HAM); Perturbation Methods.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-12 profile-info-individual">
        <div className="row">
            <div className="col-md-3"> <a
                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16390"
                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16390/16390.jpg" alt=""
                    className="img-fluid" width="150" height="180"/> </a> </div>
            <div className="col-md-9">
                <div className="card ">
                    <div className="data">
                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16390"
                            target="_blank">Prof. Reddy Y N</a>
                        </h5> <span>Professor</span>
                        <p className="contact-info"> <i className="fa fa-phone"
                            aria-hidden="true"></i> 8332969447, 9849268724
                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                            <a href="mailto:ynreddy@nitw.ac.in "> ynreddy@nitw.ac.in  </a>
                           </p>
                         <p className="justified-text"> Numerical Analysis</p>
                    </div>
                </div>
            </div>
        </div>
    </div> */}

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-satya"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16942/16942.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-satya"
                                    target="_blank">Prof. Satyanarayana Engu</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9611422816
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:satya@nitw.ac.in"> satya@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Nonlinear Partial differential equations </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-ysr"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16898/16898.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-ysr"
                                    target="_blank">Prof. Sreenivasa Rao Y</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9494776866
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:ysr@nitw.ac.in"> ysr@nitw.ac.in </a> </p>
                                  <p className="justified-text"> Cryptography, Cloud Security, Blockchain Security.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-jsrinivas"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16698/16698.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-jsrinivas"
                                    target="_blank">Prof. Srinivas Jangili</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9849835490 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jsrinivas@nitw.ac.in"> jsrinivas@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">Microfluidics; Nanofluidics; Electrokinetic Transport Phenomena; Multi-Layer Flows; Heat Transfer Analysis; Analytical and Semi-analytical Methods;</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17006/17006.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ma-tpshukla"
                                    target="_blank">Prof. Triveni Prasad Shukla</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9702961319 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:tpshukla@nitw.ac.in "> tpshukla@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Quasilinear Partial Differential Equations; Nonlinear Waves; Classical and Nonclassical Shock Waves; Dispersive Shock Waves; Undercompressive Shock Waves</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>






                      </section>




                    </div>




                    {/* <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_A._Jagadeesh_Kumar_2023-10-6-12-52-11.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri A. Jagadeesh Kumar</h6> <span>Senior Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969564 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jagadeesh@nitw.ac.in">jagadeesh@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_Md._Farooq_Azam_2023-10-6-12-53-54.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri Md. Farooq Azam</h6> <span>Junior Asst. (Upg)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969567 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mdfarooqazam@nitw.ac.in">mdfarooqazam@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_Bharat_Singh_2023-10-6-12-55-3.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri Bharat Singh</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8004444037 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bharats@nitw.ac.in">bharats@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Dr._S._Satyannarayana_2023-10-6-12-56-42.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Dr. S. Satyannarayana</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8688435550 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:satyann@nitw.ac.in">satyann@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_M._Uma_Maheshwar_Reddy_2023-10-6-12-57-36.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri M. Uma Maheshwar Reddy</h6> <span>Sr. Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9154762431 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mandau@nitw.ac.in">mandau@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                             
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_N._Goutham_2023-10-6-12-58-42.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri N. Goutham</h6> <span>Technician
                                                                    </span>
                                                                <div className=" mt-2">
                                                                <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7702516189 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ngoutham@nitw.ac.in">ngoutham@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_G._Ilaiah_2023-10-6-13-0-32.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri G. Ilaiah</h6>
                                                                <span>Caretaker SG-II</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9177155317 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ilaiahgaja@nitw.ac.in">ilaiahgaja@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_N._Sammaiah_2023-10-6-13-1-33.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri N. Sammaiah</h6> <span>Sr. Caretaker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9398676745 </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               


                                            </div>

                                        </div> */}





                    <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">
                      {/*<h4 className="">Ph.D. Awardee</h4>
                     <p className="justified-text">
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






                      <div className="row phd-std-row">





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Atul_Kumar_Shriwastva_2023-10-10-12-59-48.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Algebraic Coding Theory </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Atul Kumar Shriwastva</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p className="contact-info">  7985508236 <br /> <span> <a href="mailto:shriwastvaatul@gmail.com">shriwastvaatul@gmail.com</a></span> </p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Archana_Bhat_2023-10-10-15-33-2.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Functional Analysis &Linear Algebra  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Archana Bhat</h6>
                            <span>Full-Time Research Scholar (Inspire)</span>
                            <hr />

                            <p className="contact-info">  -  <br /> <span> <a href="mailto:archanadabguli@gmail.com ">archanadabguli@gmail.com </a> </span></p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Smriti_Tiwari_2023-10-10-15-34-50.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Partial Differential Equations  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Smriti Tiwari</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  -  <br /> <span><a href="mailto:tiwarismriti19@gmail.com ">tiwarismriti19@gmail.com </a></span>  </p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Shravan_Kumar_R_2023-10-10-15-36-43.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Fluid Dynamics  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Shravan Kumar R</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9866948364  <br />  <span><a href="mailto:shravan8720@gmail.com ">shravan8720@gmail.com</a></span></p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Nitish_Gupta_2023-10-10-15-38-12.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Fluid Dynamics  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Nitish Gupta</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 6392285639  <br /> <span> <a href="mailto:gnitish@student.nitw.ac.in ">gnitish@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Reddimalla_Naresh_2023-10-10-15-40-10.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Fluid Mechanics </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Reddimalla Naresh</h6>
                            <span>Full-Time Research Scholar (Inspire)</span>
                            <hr />

                            <p className="contact-info"> 8096697498 <br /> <span> <a href="mailto:reddymallanaresh8@gmail.com  ">reddymallanaresh8@gmail.com </a> </span></p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Nidhi_Humnekar_2023-10-10-15-41-43.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Computational Fluid Dynamics  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Nidhi Humnekar</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> - <br /> <span> <a href="mailto:nidhihumnekar17@gmail.com ">nidhihumnekar17@gmail.com </a></span> </p>


                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Subhabrata_Rath_2023-10-10-15-43-17.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Operation Research  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Subhabrata Rath</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 6371739705 <br /> <span> <a href="mailto:subha.bratarath17@gmail.com ">subha.bratarath17@gmail.com  </a></span> </p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Harlal_Saran_2023-10-10-15-44-49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Fluid Dynamics  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Harlal Saran</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9680003978 <br />  <span><a href="mailto:harlalsaran6@gmail.com ">harlalsaran6@gmail.com </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Sourav_Bera_2023-10-10-15-46-24.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Cryptography   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Sourav Bera</h6>
                            <span>Full-Time Research Scholar (Inspire)</span>
                            <hr />

                            <p className="contact-info">  9007589477 <br />  <span><a href="mailto:berasourav561@gmail.com  ">berasourav561@gmail.com  </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Bikash_Modak_2023-10-10-15-48-11.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Mathematical Modeling   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Bikash Modak</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8910633240 <br /> <span> <a href="mailto:bikash1995modak@gmail.com ">bikash1995modak@gmail.com  </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Pankaj_Barman_2023-10-10-15-49-43.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Stability Analysis  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Pankaj Barman</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8918395454 <br /> <span> <a href="mailto:barmanp60@gmail.com  ">barmanp60@gmail.com  </a> </span></p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Khushbu_Singh_2023-10-10-15-53-24.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Bio-Mathematics  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Khushbu Singh</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8081082837 <br /> <span> <a href="mailto:khushbu91@student.nitw.ac.in  ">khushbu91@student.nitw.ac.in  </a> </span></p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Sweta_2023-10-10-15-55-7.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Fluid Dynamics </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Sweta</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8949690352 <br /> <span> <a href="mailto:swetabajawa@gmail.com  ">swetabajawa@gmail.com  </a> </span></p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Suryakant_Prasad_2023-10-10-15-56-48.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Cryptography </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Suryakant Prasad</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 7091672584 <br /> <span> <a href="mailto:sp720100@student.nitw.ac.in ">sp720100@student.nitw.ac.in  </a></span> </p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Nila_B_2023-10-10-15-58-41.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Operations Research  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Nila B</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9744944680 <br /> <span> <a href="mailto:nb720101@student.nitw.ac.in ">nb720101@student.nitw.ac.in   </a> </span></p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Evana_Islam_Sarkar_2023-10-10-16-0-15.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>  PDE  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Evana Islam Sarkar</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">   8317823164 <br /> <span> <a href="mailto:ei720102@student.nitw.ac.in  ">ei720102@student.nitw.ac.in  </a> </span></p>


                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Saritha_Gorantala_2023-10-10-16-2-2.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Saritha Gorantala</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9908567665 <br /> <span> <a href="mailto:sg720104@student.nitw.ac.in"> sg720104@student.nitw.ac.in </a></span> </p>


                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Brijesh_Kumar_2023-10-10-16-3-24.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Brijesh Kumar</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 7745956370 <br />  <span> <a href="mailto:bk721099@student.nitw.ac.in "> bk721099@student.nitw.ac.in  </a> </span></p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Anomitra_Chakraborty_2023-10-10-16-4-41.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Anomitra Chakraborty</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9007146842 <br /> <span> <a href="mailto:ac712148@student.nitw.ac.in "> ac712148@student.nitw.ac.in   </a></span> </p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Sapavat_Bixapathi_2023-10-10-16-6-36.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Sapavat Bixapathi</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 7995826334 <br /> <span> <a href="mailto:sb712149@student.nitw.ac.in  "> sb712149@student.nitw.ac.in   </a></span> </p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Preeti_Priya_2023-10-10-16-9-29.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Algebraic Coding Theory    </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Preeti Priya</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9711232735 <br /> <span> <a href="mailto:PP712150@student.nitw.ac.in  "> PP712150@student.nitw.ac.in    </a></span> </p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ravi_Mahla_2023-10-10-16-11-59.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics    </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ravi Mahla</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9540474248 <br /> <span> <a href="mailto:rm712151@student.nitw.ac.in "> rm712151@student.nitw.ac.in  </a> </span></p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Praveen_Kumar_Anagandula_2023-10-10-16-13-17.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Epidemiological modelling   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Praveen Kumar Anagandula</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 7893117946 <br />  <span> <a href="mailto:ap712152@student.nitw.ac.in "> ap712152@student.nitw.ac.in   </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Kanchan_Dwivedi_2023-10-10-16-14-40.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> PDE  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Kanchan Dwivedi</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9461505301 <br />  <span><a href="mailto:kd712154@student.nitw.ac.in "> kd712154@student.nitw.ac.in    </a> </span></p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Krishnendu_Nayak_2023-10-10-16-16-15.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Computational Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Krishnendu Nayak</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9382436995 <br /> <span><a href="mailto:kn712156@student.nitw.ac.in "> kn712156@student.nitw.ac.in     </a></span>  </p>


                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Priyanshu_Agrahari_2023-10-10-16-18-28.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Hydrodynamic Stability   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Priyanshu Agrahari</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9565556209 <br /> <span> <a href="mailto:pa21marer02@student.nitw.ac.in "> pa21marer02@student.nitw.ac.in     </a> </span></p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Sarthak_Sharma_2023-10-10-16-19-49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Computational Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Sarthak Sharma</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  7340333966 <br />  <span> <a href="mailto:ss21marer03@student.nitw.ac.in "> ss21marer03@student.nitw.ac.in   </a> </span></p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Duryodhan_Subudhi_2023-10-10-16-21-50.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Duryodhan Subudhi</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 7978302533 <br /> <span> <a href="mailto:ds21marer04@student.nitw.ac.in "> ds21marer04@student.nitw.ac.in    </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mrittika_Das_2023-10-10-16-23-18.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Advanced Computational Methods   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mrittika Das</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8413013720 <br />  <span><a href="mailto:mrittika.das1697@gmail.com "> mrittika.das1697@gmail.com     </a> </span> </p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Heena_2023-10-10-16-26-37.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Numerical Analysis   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Heena</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9695217364 <br />  <span><a href="mailto:hh21marer06@student.nitw.ac.in  "> hh21marer06@student.nitw.ac.in     </a></span> </p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Neelam_2023-10-10-16-27-51.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> PDE  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Neelam</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  8385908805 <br /> <span> <a href="mailto:nn21marer07@student.nitw.ac.in "> nn21marer07@student.nitw.ac.in   </a> </span></p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ajay_Kumar_2023-10-10-16-29-29.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ajay Kumar</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8507508987 <br /> <span> <a href="mailto:ak21marer08@student.nitw.ac.in "> ak21marer08@student.nitw.ac.in    </a> </span></p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Anil_Kumar_2023-10-10-16-31-0.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Anil Kumar</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9559371654 <br /> <span> <a href="mailto:ak21marer01@student.nitw.ac.in"> ak21marer01@student.nitw.ac.in   </a> </span></p>


                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ferqunda_Tabassum_2023-10-10-16-32-30.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Bio Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ferqunda Tabassum</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  7675098750 <br /> <span> <a href="mailto:ft712158@student.nitw.ac.in "> ft712158@student.nitw.ac.in   </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Vivek_Lodwal_2023-10-10-16-40-49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Vivek Lodwal</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8740974717 <br /> <span> <a href="mailto:vl22mar1r07@student.nitw.ac.in "> vl22mar1r07@student.nitw.ac.in  </a> </span></p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Pavan_Sharma_2023-10-10-16-42-12.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Operations Research    </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Pavan Sharma</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  8384928249 <br /> <span> <a href="mailto:ps22mar1r05@student.nitw.ac.in "> ps22mar1r05@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Pooja_Kumari_2023-10-10-16-43-35.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Operations Research    </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Pooja Kumari</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  7042345015 <br /> <span> <a href="mailto:pk22mar1r02@student.nitw.ac.in"> pk22mar1r02@student.nitw.ac.in </a></span> </p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Atul_Kaushik_2023-10-10-16-45-23.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Atul Kaushik</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9643434033 <br /> <span> <a href="mailto:kaushikatul515@gmail.com  "> kaushikatul515@gmail.com    </a></span> </p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Antara_Avinash_Wajpe_2023-10-10-16-46-49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Mechanics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Antara Avinash Wajpe</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9960459508 <br /> <span><a href="mailto:wa22mar1r01@student.nitw.ac.in"> wa22mar1r01@student.nitw.ac.in  </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Subham_Jangid_2023-10-10-16-48-18.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics    </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Subham Jangid</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 7791810742 <br />  <span><a href="mailto:sj22mar1r03@student.nitw.ac.in"> sj22mar1r03@student.nitw.ac.in  </a></span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/V_Hemalatha_2023-10-10-16-49-52.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">V Hemalatha</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 6309737107 <br />  <span>  <a href="mailto:vh22mar1r04@student.nitw.ac.in"> vh22mar1r04@student.nitw.ac.in  </a></span> </p>


                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Velisoju_Swathi_2023-10-10-16-51-29.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Velisoju Swathi</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9603132521 <br />  <span> <a href="mailto:vs22mar1q01@student.nitw.ac.in"> vs22mar1q01@student.nitw.ac.in  </a></span> </p>


                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Rajesh_Chary_Kandukoori_2023-10-10-16-52-46.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Rajesh Chary Kandukoori</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9010517881 <br /> <span> <a href="mailto:rc22mar2r01@student.nitw.ac.in"> rc22mar2r01@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Rishav_Aich_2023-10-10-16-54-18.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Rishav Aich</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9641246686 <br /> <span> <a href="mailto: ra22mar2r02@student.nitw.ac.in">  ra22mar2r02@student.nitw.ac.in </a></span> </p>


                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Govind_Gaur_2023-10-10-16-55-39.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> -  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Govind Gaur</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  7505470697 <br /> <span><a href="mailto: gg22mar2s01@student.nitw.ac.in">  gg22mar2s01@student.nitw.ac.in </a></span>  </p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Pravesh_kumar_2023-10-10-16-56-58.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Computational Fluid Dynamics   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Pravesh kumar</h6>
                            <span> Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9580937178 <br /> <span> <a href="mailto:  pk22mar2r03@student.nitw.ac.in">  pk22mar2r03@student.nitw.ac.in </a></span> </p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Bappa_Mondal_2023-10-10-17-3-38.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Inventoy and Supply-Chain	Management  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Bappa Mondal</h6>
                            <span> Postdoctoral Fellow </span>
                            <hr />

                            <p className="contact-info">  9832964152 <br /> <span><a href="mailto: pdf_2022_ma01@nitw.ac.in">  pdf_2022_ma01@nitw.ac.in </a> </span> </p>


                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ramakrishna_Manchi_2023-10-10-17-4-23.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Bio-Fluid Mechanics  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ramakrishna Manchi </h6>
                            <span> Postdoctoral Fellow </span>
                            <hr />

                            <p className="contact-info"> 9655933590 <br /> <span> <a href="mailto:  pdf_2023_ma04@nitw.ac.in ">  pdf_2023_ma04@nitw.ac.in  </a> </span></p>


                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Dr._Abdul_Haseeb_Ganie_2023-10-10-17-5-11.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b> Fuzzy Information Theory  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Dr. Abdul Haseeb Ganie  </h6>
                            <span> Postdoctoral Fellow </span>
                            <hr />

                            <p className="contact-info"> 9906836244 <br />  <span><a href="mailto:pdf_2023_ma03@nitw.ac.in ">  pdf_2023_ma03@nitw.ac.in   </a> </span></p>


                          </div>
                        </div>



                      </div>



                    </div>
                  </div>
                </div>
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">
                  <div className="row std-society">
                    {/* <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                target="_blank"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                                                    target="_blank">
                                                    <h6>Prof. R. Padmavathy</h6> <span> Faculty Advisor </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9490165347 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:cse_hod@nitw.ac.in"
                                                            style={{ color: "#808080" }}>cse_hod@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                                                    target="_blank">
                                                    <h6>Prof. U. S. N. Raju</h6> <span> Treasurer </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:usnraju@nitw.ac.in"
                                                            style={{ color: "#808080" }}>usnraju@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">


                  <div className="row notable-alumni">
                    {/* <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Dr. Pushmeet Kohli</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Principal Scientist and Team Lead, DeepMind,
                                                        London, UK
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Pavithra Prabhakar</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Professor, Kansas State University, Manhattan,
                                                        United States
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/vrajanna.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>V Rajanna</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> GM & VP, TCS- Hyderabad


                                                    </p>

                                           
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/aarthi.jpg  " alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Aarthi Subramanyan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Group Chief Digital Officer, Tata Sons Private
                                                        Limited, Mumbai
                                                    </p>

                                              
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/murali.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Murali Annavaram</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Professor at University of Southern California

                                                    </p>

                                                
                                                </div>
                                            </div>
                                        </div> */}

                    {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Sridhar Muppala</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO, Silicon Valley

                                                    </p>

                                               
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Movva Ramu</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Microsoft Research Centre, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>K V Sridhar</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO Analytics CA, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Jitendra Lakotia</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Former VP of Cognos. USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Amarnadh Reddy</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CTO, CGG, Hyderabad
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}


                    {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Feroz khan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Dy. Director, SAP Labs

                                                    </p>

                                                </div>
                                            </div>
                                        </div> */}


                  </div>
                </div>

                {/* <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Sustainable Energy Technologies
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16325/16325.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Surananai Srinath</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462624 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:srinath@nitw.ac.in">srinath@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Sarath Babu </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462610 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sarat@nitw.ac.in">sarat@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. Anand Kishore</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462623 <br/> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. V. Suresh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462628 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsuresh@nitw.ac.in">pvsuresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vidyasagar Shilapuram</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462634 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vidyasagars@nitw.ac.in">vidyasagars@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16429/16429.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S. Murali Mohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:murali@nitw.ac.in">murali@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16422/16422.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Uday Bhaskar Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462629 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:udaybhaskar@nitw.ac.in">udaybhaskar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16465/16465.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V. Ramsagar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462632 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramsagar@nitw.ac.in">ramsagar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                        </div>

                                    
                                  </div> */}



                <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                  aria-labelledby="nav-AdjunctFaculty-tab">

                  <div className="col-md-12 profile-info-individual">
                    <div className="row">

                      <div className="col-md-9">
                        <div className="card">
                          <div className="d-flex align-items-center">
                            {/* <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Tata_Narasinga_Rao_2023-10-9-16-55-33.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div> */}
                            <div className="ml-3 w-100">
                              <h6 className="mb-0 mt-0"> Dr. Nithish Kumar Gopagani </h6>
                              <div className=" mt-2">
                                <p className="contact-info"> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:nithishkumar.gopagani@gmail.com">nithishkumar.gopagani@gmail.com</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="col-md-9">
                        <div className="card">
                          <div className="d-flex align-items-center">
                            {/* <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sharma_2023-10-9-16-56-57.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div> */}
                            <div className="ml-3 w-100">
                              <h6 className="mb-0 mt-0"> Dr. T. Senthilkumar </h6>
                              <div className=" mt-2">
                                <p className="contact-info"> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:tskumar2410@gmail.com">tskumar2410@gmail.com</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>


                </div>



                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">
                </div>

                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">

                  {/* <div className="details"> 
                                
								<h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span>
								</div> 
                                <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/>  */}

                  {/* <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 

                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}





                </div>






              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default MathsDeptPage;